import { LegalNameForm, LegalEntitiesForm, AffiliatesForm, LocationsForm, OfficerForm, FrameworkForm, PeriodForm, StakeholdersForm, ExternalAssuranceForm, ConsultantForm, AuditorForm, ReportingFormatForm, GeneralDisclosuresForm, MaterialTopicsForm, TopicDisclosuresForm, StatementOfUseForm, NotifyGRIForm, PublishGRIIndexForm, FacilitiesForm, DataCentersForm, ProcessSupplierMaterials, ProcessTaxonomyForm, ProcessInfrastructureForm, ProcessDetailsForm } from '../forms';
import { affiliates, legalEntities, legalName, locations, officer, framework, period, stakeholders, externalAssurance, consultant, auditor, generalDisclosures, materialTopics, topicDisclosures, reportingFormat, notifyGRI, publishGRIIndex, statementOfUse, facilities, datacenters, processDetails, processTaxonomy, processMaterials, processInfrastructureSchema, processCreate } from '../validationSchema';
import { Affiliates, Framework, LegalEntities, LegalName, Locations, Officer, Period, ReportingFormat, Stakeholders, ExternalAssurance, Consultant, Auditor, GeneralDisclosures, MaterialTopics, TopicDisclosures, StatementOfUse, NotifyGRI, PublishGRIIndex, DataCenters, ProcessTaxonomy, ProcessMaterials, ProcessDetails, ProcessInfrastructure, Process } from '../types';

export const getInitialValuesOrDefault = <T>(key: string, defaultValues?: T): T => {
  const savedValues = localStorage.getItem(key);
  return savedValues ? JSON.parse(savedValues) : defaultValues;
}

export const getInitialMapForEdit = <T>(key: string, defaultValues: T): T => {
  const savedValues = JSON.parse(localStorage.getItem(key) || '{}');
  
  if(!Object.keys(savedValues).length) return defaultValues;

  // @ts-ignore
  const returnKeys = Object.keys(defaultValues);
  returnKeys.forEach((key: any) => {
    // @ts-ignore
    defaultValues[key] = savedValues[key];
  });
  return defaultValues;
}

export const organizationalDetailsFormConfigs = [
  {
    id: 'legalName',
    title: 'Legal Name',
    initialValues: { name: '' } as LegalName,
    validationSchema: legalName,
    content: LegalNameForm,
  },
  {
    id: 'legalEntities',
    title: 'Legal Entities',
    initialValues: { legalEntities: [{ name: "", type: '', economicActivityCodeIDs: [] }] } as LegalEntities,
    validationSchema: legalEntities,
    content: LegalEntitiesForm,
  },
  {
    id: 'locations',
    title: 'Locations',
    initialValues: { locations: [{ name: "", countryID:'335d7ddf-c3cd-4e71-8ec9-f89501eb7727', cityID: "", ownership: "", type: "Location" }] } as Locations,
    validationSchema: locations,
    content: LocationsForm,
  },
  {
    id: 'facilities',
    title: 'Facilities',
    initialValues: { locations: [{ name: "", countryID:'335d7ddf-c3cd-4e71-8ec9-f89501eb7727', cityID: "", ownership: "", type: "Facility" }] } as Locations,
    validationSchema: locations,
    content: FacilitiesForm,
  },
];

// export const frameworkFormConfigs = [
//   {
//     id: 'framework',
//     title: 'Framework',
//     initialValues: getInitialValuesOrDefault<Framework>('framework', { framework: "" }),
//     validationSchema: framework,
//     content: FrameworkForm,
//   }
// ];

// export const reportingFormatConfigs = [
//   {
//     id: 'reportingFormat',
//     title: 'Reporting Format',
//     initialValues: getInitialValuesOrDefault<ReportingFormat>('reportingFormat', { reportingFormat: "" }),
//     validationSchema: reportingFormat,
//     content: ReportingFormatForm,
//   },
// ];

// export const esgDetailsFormConfigs = [
//   {
//     id: 'period',
//     title: 'Period',
//     initialValues: getInitialValuesOrDefault<Period>('period', { startDate: null, endDate: null }),
//     validationSchema: period,
//     content: PeriodForm,
//   },
//   {
//     id: 'stakeholders',
//     title: 'Stakeholders',
//     initialValues: getInitialValuesOrDefault<Stakeholders>('stakeholders', { stakeholders: [] }),
//     validationSchema: stakeholders,
//     content: StakeholdersForm,
//   },
//   {
//     id: 'externalAssurance',
//     title: 'External Assurance',
//     initialValues: getInitialValuesOrDefault<ExternalAssurance>('externalAssurance', { externalAssurance: '' }),
//     validationSchema: externalAssurance,
//     content: ExternalAssuranceForm,
//   },
//   {
//     id: 'consultant',
//     title: 'Consultant',
//     initialValues: getInitialValuesOrDefault<Consultant>('consultant', { consultant: '' }),
//     validationSchema: consultant,
//     content: ConsultantForm,
//   },
//   {
//     id: 'auditor',
//     title: 'Auditor',
//     initialValues: getInitialValuesOrDefault<Auditor>('auditor', { auditor: '' }),
//     validationSchema: auditor,
//     content: AuditorForm,
//   },
// ];

// export const requirementsConfigs = [
//   {
//     id: 'generalDisclosures',
//     title: 'General Disc.',
//     initialValues: getInitialValuesOrDefault<GeneralDisclosures>('generalDisclosures', { disclosures: [
//       { name: 'Organizational details', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Entities', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Reporting period, frequency and contact point', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Restatements of information', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'External assurance', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Activities, value chain & otherbusiness relationships', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Employees', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Workers but not employees', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Governance structure & composition', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Nomination & selection', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Chair of the highest governance body', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Role of the chair in overseeing management impacts', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Delegation of responsibility for managing impacts', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Role of the highest governance body in sustainability reporting', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Conflict of interest', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Communication of critical concerns', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Collective knowledge of the highest governance body', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Evaluation of the performance of the highest governance body ', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Remuneration policies', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Process to determine remuneration', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Annual total compensation ratio', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Statement on sustainable development strategy', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Policy commitments', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Embedding policy commitments', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Processes to remediate negative impacts', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Mechanisms for seeking advice and raising concerns', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Compliance with laws and regulations', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Membership associations', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Approach to stakeholder engagement', location: '', reqOmitted: '', reason: '', explanation: '' },
//       { name: 'Collective bargaining agreements', location: '', reqOmitted: '', reason: '', explanation: '' },
//     ] }),
//     validationSchema: generalDisclosures,
//     content: GeneralDisclosuresForm,
//   },
//   {
//     id: 'materialTopics',
//     title: 'Material Topics',
//     initialValues: getInitialValuesOrDefault<MaterialTopics>('materialTopics', { materialTopics: [{ name: '', location: ''}] }),
//     validationSchema: materialTopics,
//     content: MaterialTopicsForm,
//   },
//   {
//     id: 'topicDisclosures',
//     title: 'Topic Disc.',
//     initialValues: getInitialValuesOrDefault<TopicDisclosures>('topicDisclosures', { topicDisclosures: [{ name: '', location: '', reqOmitted: '', reason: '', explanation: '' }] }),
//     validationSchema: topicDisclosures,
//     content: TopicDisclosuresForm,
//   },
//   {
//     id: 'statementOfUse',
//     title: 'Statement of use',
//     initialValues: getInitialValuesOrDefault<StatementOfUse>('statementOfUse', { name: '', startDate: null, endDate: null }),
//     validationSchema: statementOfUse,
//     content: StatementOfUseForm,
//   },
//   {
//      id: 'notifyGRI',
//      title: 'Notify GRI',
//      initialValues: getInitialValuesOrDefault<NotifyGRI>('notifyGRI', { isNotified: true }),
//      validationSchema: notifyGRI,
//      content: NotifyGRIForm,
//    },
//    {
//      id: 'publishGRIIndex',
//      title: 'Publish GRI',
//      initialValues: getInitialValuesOrDefault<PublishGRIIndex>('publishGRIIndex', { publishOn: [] }),
//      validationSchema: publishGRIIndex,
//      content: PublishGRIIndexForm,
//    },
// ];

export const processCreateFormConfig = {
  id: 'processDetails',
  title: 'Process details',
  initialValues: getInitialMapForEdit<Partial<Process>>('newProcess', { name: '', currencyID: 'ee95a6d0-50cb-49d3-b040-bfbb2cef10d4' }),
  validationSchema: processCreate,
  content: ProcessDetailsForm,
};


export const processFormConfigs = [
  {
    id: 'processDetails',
    title: 'Process details',
    initialValues: getInitialMapForEdit<ProcessDetails>('newProcess', { id: `${Date.now()}_subP`, processName: '', time: '', timeUnit: 'hours', processOwner: '' }),
    validationSchema: processDetails,
    content: ProcessDetailsForm,
  },
  {
    id: 'taxonomy',
    title: 'Taxonomy',
    initialValues: getInitialValuesOrDefault<ProcessTaxonomy>('newProcess', { location: '', naceCodes: null, hrProcess: false, iscoCode: '' }),
    validationSchema: processTaxonomy,
    content: ProcessTaxonomyForm,
  },
  {
    id: 'supplier',
    title: 'Materials',
    initialValues: getInitialValuesOrDefault<ProcessMaterials>('newProcess', {supplier: '', rawMaterialsData: []}),
    validationSchema: processMaterials,
    content: ProcessSupplierMaterials,
  }, 
  {
    id: 'infrastructureAssets', 
    title: 'Infrastructure assets',
    initialValues: getInitialValuesOrDefault<ProcessInfrastructure>('newProcess', { infraAssets: [], consumables: [] }),
    validationSchema: processInfrastructureSchema,
    content: ProcessInfrastructureForm, 
  },
];
