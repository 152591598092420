import { useField } from "formik";
import { SelectChangeEvent, SelectProps, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Box, ListSubheader, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from "react";

type AppSelectWithFilterProps = SelectProps & {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  validate?: (value: string) => string | undefined;
};

export const AppSelectWithFilter: React.FC<AppSelectWithFilterProps> = ({ name, label, options, validate, ...props }) => {
  const [field, meta, helpers] = useField<string>({ name, validate });
  const hasError = (meta.touched && meta.error) ? true : false;

  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [filterText]);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const target = event.target as typeof event.target & { value: string };
    helpers.setValue(target.value);
    if (props.onChange) {
      props.onChange(event, child);
    }
  };

  return (
    <FormControl fullWidth variant="outlined" size="small" error={hasError}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        id={name}
        labelId={`${name}-label`}
        label={label}
        value={field.value}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              minHeight: 300,
              maxHeight: 300,
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            },
          },
        }}
      >
        <ListSubheader sx={{ backgroundColor: 'white', zIndex: 1 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Type to filter..."
            value={filterText}
            onChange={handleFilterChange}
            inputRef={inputRef}
            inputProps={{
              'aria-label': 'filter options',
            }}
          />
        </ListSubheader>
        {filteredOptions.length === 0 ? (
          <MenuItem disabled>No results found</MenuItem>
        ) : (
          filteredOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              <Tooltip title={label}>
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {label}
                </Box>
              </Tooltip>
            </MenuItem>
          ))
        )}
      </Select>
      {hasError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
