import * as Yup from "yup";

export const period = Yup.object({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date().required("End date is required"),
});

export const stakeholders = Yup.object({
    stakeholders: Yup.array().min(1, "Stakeholder is required"),
});

export const externalAssurance = Yup.object({
    externalAssurance: Yup.string().required("External Assurance is required"),
});

export const consultant = Yup.object({
    consultant: Yup.string().required("Consultant is required"),
});

export const auditor = Yup.object({
    auditor: Yup.string().required("Auditor is required"),
});