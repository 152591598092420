import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { City } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';

interface CitiesState {
    values: City[];
    loading: boolean;
    error: string | null;
    lastFetched: number | null;
}

interface FetchCitiesParams {
    params: URLSearchParams;
}


const initialState: CitiesState = {
    values: [],
    loading: false,
    error: null,
    lastFetched: null,
};

export const fetchCities = createAsyncThunk(
    'cities/fetchCities',
    async ({ params }: FetchCitiesParams, { getState, rejectWithValue }) => {
        const state = getState() as { cities: CitiesState };
        if (state.cities.values.length > 0) {
            // If cities are already fetched, return early
            return state.cities.values;
        }

        try {
            const response = await axiosClient.General.getCities(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCities.fulfilled, (state, action: PayloadAction<City[]>) => {
                state.values = action.payload;
                state.loading = false;
                state.lastFetched = Date.now();
            })
            .addCase(fetchCities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default citiesSlice.reducer;