import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { TenantInfo } from '../../app/types';
import { RootState } from '../store';

interface TenantInfoState {
    data: TenantInfo | null;
    loading: boolean;
    error: string | null;
}

const initialState: TenantInfoState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchTenantInfo = createAsyncThunk(
    'tenant/fetchTenantInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Tenant.getTenant();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const tenantInfoSlice = createSlice({
    name: 'tenantInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenantInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTenantInfo.fulfilled, (state, action: PayloadAction<TenantInfo>) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchTenantInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default tenantInfoSlice.reducer;
export const selectTenantInfo = (state: RootState) => state.tenantInfo;
