import { Button, Grid } from "@mui/material"
import { AppForm, AppSelectMultiple, AppTextField } from "../../common"
import { Supplier } from "../../types"
import { supplier } from "../../validationSchema"
import { Field } from "formik"

interface ISupplierFormProps {
    onSubmit: (values: Supplier) => void;
    onCancel: () => void;
    codeOptions: any[];
}

export const SupplierForm: React.FC<ISupplierFormProps> = ({ onSubmit, onCancel, codeOptions }) => {

    const initialValues: Supplier = { name: '', country: '', city: '', supplierAddress: '', category: '', naceCodes: null, supplierContact: '', label: '' };

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={supplier}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            <Grid container columnSpacing={4} sx={{ width:'500px' }}>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="name"
                        as={AppTextField}
                        label="Supplier Name *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="country"
                        as={AppTextField}
                        label="Country *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="city"
                        as={AppTextField}
                        label="City *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="supplierAddress"
                        as={AppTextField}
                        label="Supplier Address *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="category"
                        as={AppTextField}
                        label="Category *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="naceCodes"
                        as={AppSelectMultiple}
                        label="NACE Codes"
                        size="small"
                        options={codeOptions || []}
                    />
                </Grid>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="supplierContact"
                        as={AppTextField}
                        label="Supplier Contact"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}