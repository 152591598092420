import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { ProductListSearchFilter } from './ProductListSearchFilter';
import { ProductListTable } from './ProductListTable';
import { ProductListActions } from './ProductListActions';
import { AppDraggableModal } from '../../../app/common';
import { Operation, Process, Product } from '../../../app/types';
import { OperationForm, ProcessAddForm, ProductAddForm } from '../../../app/forms';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { createOperation, createProduct, deleteProduct, fetchProducts } from '../../../store/slices/productsSlice';
import { updateProcess } from '../../../store/slices/processesSlice';

type ModalKey = 'addOperation' | 'addProcess' | 'addProduct';

export const ValueChainProducts: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [filterQuery, setFilterQuery] = useState('');
    const [modalStates, setModalStates] = useState<{[key in ModalKey]: { isOpen: boolean; data: Product | Operation | null }}>({
        addOperation: { isOpen: false, data: null },
        addProcess: { isOpen: false, data: null },
        addProduct: { isOpen: false, data: null }
    });

    const { list, loading, error } = useSelector((state: RootState) => state.products);

    useEffect(() => {
        dispatch(fetchProducts());
    }, []);

    const toggleModal = (modalKey: ModalKey, data: Product | Operation | null = null) => {
        setModalStates(prev => {
            const isOpen = prev[modalKey].isOpen;
            return {
                ...prev,
                [modalKey]: { isOpen: !isOpen, data },
            };
        });
    };

    const handleOperationFormSubmit = (values: Operation) => {
        dispatch(createOperation(values));
        toggleModal('addOperation');
    };

    const handleProductAddFormSubmit = (product: Product) => {
        dispatch(createProduct(product));
        toggleModal('addProduct');
    };

    const handleProcessFormSubmit = (values: Process) => {
        const operationID = modalStates.addProcess.data?.id || '';
        dispatch(updateProcess({ operationID , id: values.id }));
    };
    
    const openAddProductModal = () => {
        toggleModal('addProduct');
    };

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#757575' }}>
                    Products & Services
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <ProductListSearchFilter onFilterChange={setFilterQuery} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ProductListActions addNewProduct={openAddProductModal} />
                    </Grid>
                </Grid>
            </Box>
            <ProductListTable
                data={list}
                onAddOperation={(product) => toggleModal('addOperation', product)}
                onAddProcess={(operation) => {toggleModal('addProcess', operation)}}
                onDeleteProduct={(productID: string) => dispatch(deleteProduct(productID))} 
            />

            <AppDraggableModal
                title={`Add New Operation for ${modalStates?.addOperation?.data?.name}`}
                open={modalStates.addOperation.isOpen}
                onClose={() => toggleModal('addOperation')}
            >
                <OperationForm
                    onSubmit={handleOperationFormSubmit}  
                    onCancel={() => toggleModal('addOperation')} 
                    productId={modalStates?.addOperation?.data?.id || ''} 
                />
            </AppDraggableModal>

            <AppDraggableModal
                title={`Add Process for Operation`}
                open={modalStates.addProcess.isOpen}
                onClose={() => toggleModal('addProcess')}
            >
                <ProcessAddForm
                    onSubmit={(values) => handleProcessFormSubmit(values)}  
                    onCancel={() => toggleModal('addProcess')} 
                    productAndProcess={modalStates.addProcess.data}
                />
            </AppDraggableModal>

            <AppDraggableModal
                title={'Add New Product'}
                open={modalStates.addProduct.isOpen}
                onClose={() => toggleModal('addProduct')}
            >
                <ProductAddForm
                    onSubmit={(values) => handleProductAddFormSubmit(values as any)}  
                    onCancel={() => toggleModal('addProduct')} 
                />
            </AppDraggableModal>
        </>
    );
};
