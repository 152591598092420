import { useField } from "formik";
import { SelectChangeEvent, SelectProps, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

type AppSelectProps = SelectProps & {
    name: string;
    label: string;
    options: { value: string; label: string }[];
    onChange: (event: SelectChangeEvent<unknown>) => void;
    validate?: (value: string) => string | undefined;
};

export const AppSelect:React.FC<AppSelectProps> = ({ name, label, options, onChange, validate, ...props }) => {

    const [field, meta] = useField<string>({ name, validate });
    const hasError = (meta.touched && meta.error) ? true : false;

    return (
        <FormControl fullWidth variant="outlined" size="small" error={hasError}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
                {...field}
                {...props}
                id={name}
                labelId={`${name}-label`}
                label={label}
                value={field.value}
                onChange={onChange}
                sx={{
                    flexGrow: '0'
                }}
            >
                {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
                ))}
            </Select>
            {hasError && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    )
}
