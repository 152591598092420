import * as Yup from "yup";

export const infraAsset = Yup.object({
    type: Yup.string().required("Asset Type is required"),
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location is required"),
    energySource: Yup.string().required("Energy consumption is required"),
    consumption: Yup.number().required("Energy consumption is required"),
    cost: Yup.number().required("Energy consumption is required"),
    assetTypeIT: Yup.string().when('type', (type, schema) => {
        return type.toString() === 'IT' ? schema.required('Asset type is required') : schema.notRequired()
    }),
    locationType: Yup.string().when('type', (type, schema) => {
        return type.toString() === 'IT' ? schema.required('Location type is required') : schema.notRequired()
    }),
});

export const infraAssetCreateSchema = Yup.object({
    infrastructureID: Yup.string().required('Select an infrastructure'),
    duration: Yup.string().required('Duration is required')
});

export const materialCreateSchema = Yup.object({
    materialID: Yup.string().required('Select an material'),
    unitQuantity: Yup.string().required('Quantity is required')
});

export const FTECreateSchema = Yup.object({
    fteID: Yup.string().required('Select a human resource'),
    duration: Yup.string().required('Duration is required')
});