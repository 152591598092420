import { useField, useFormikContext } from 'formik';
import { SelectChangeEvent, FormControl, FormHelperText, InputLabel, MenuItem, Select, OutlinedInput, TextField, Box, ListSubheader, Tooltip, Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useRef, useState } from 'react';

type AppSelectMultipleProps = {
  name: string;
  label: string;
  options: { value: string; label: string }[];
};

export const AppSelectMultiple: React.FC<AppSelectMultipleProps> = ({ name, label, options, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<string[]>(name);
  const hasError = Boolean(meta.touched && meta.error);

  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: SelectChangeEvent<typeof field.value>) => {
    const value = event.target.value;
    setFieldValue(name, typeof value === 'string' ? value.split(',') : value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [filterText]);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDelete = (event: React.MouseEvent, valueToDelete: string) => {
    event.stopPropagation();
    const newValue = field.value.filter(value => value !== valueToDelete);
    setFieldValue(name, newValue);
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" size="small" error={hasError} sx={{ minWidth: '120px' }}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          {...field}
          {...props}
          multiple
          value={field.value || []}
          onChange={handleChange}
          input={<OutlinedInput label={label} id={name} />}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                minHeight: 300,
                maxHeight: 300,
                overflowY: 'auto',
                scrollbarWidth: 'thin', // For Firefox
                '&::-webkit-scrollbar': {
                  width: '4px', // For Webkit browsers
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888', // Style for the thumb
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              },
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Tooltip key={value} title={options.find(option => option.value === value)?.label || value}>
                  <Chip
                    label={options.find(option => option.value === value)?.label || value}
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(event) => handleDelete(event, value)}
                    sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  />
                </Tooltip>
              ))}
            </Box>
          )}
        >
          <ListSubheader sx={{ backgroundColor: 'white', zIndex: 1 }}>
            <TextField
              sx={{ mt: 2, mb: 2 }}
              fullWidth
              size="small"
              placeholder="Type to filter..."
              value={filterText}
              onChange={handleFilterChange}
              inputRef={inputRef}
              inputProps={{
                'aria-label': 'filter options',
              }}
            />
          </ListSubheader>
          {filteredOptions.length === 0 ? (
            <MenuItem disabled>
              No results found
            </MenuItem>
          ) : (
            filteredOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value} sx={{ width:'100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {label}
              </MenuItem>
            ))
          )}
        </Select>
        {hasError && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </>
  );
};
