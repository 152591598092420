import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { InfastructureAsset } from '../../app/types';
import { fetchProcessByID } from './processesSlice';

interface InfrastructureAssetState {
    list: InfastructureAsset[] | null;
    loading: boolean;
    error: string | null;
}

const initialState: InfrastructureAssetState = {
    list: [],
    loading: false,
    error: null,
};

export const fetchInfrastructureAssets = createAsyncThunk(
    'infrastructureAssets/fetchInfrastructureAssets',
    async (params: URLSearchParams, { rejectWithValue }) => {
        try {
            const response = await axiosClient.InfrastructureAssets.getInfrastructureAssets(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const createInfrastructureAssets = createAsyncThunk(
    'infrastructureAssets/createInfrastructureAssets',
    async (infraAsset: InfastructureAsset, { rejectWithValue }) => {
        try {
            const response = await axiosClient.InfrastructureAssets.createInfrastructureAsset(infraAsset);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteInfrastructure = createAsyncThunk(
    'infrastructureAssets/deleteInfrastructure',
    async ({ infraID, processID }: {infraID: string, processID: string}, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosClient.InfrastructureAssets.deleteInfra(infraID);
            dispatch(fetchProcessByID(processID));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const infrastructureAssetsSlice = createSlice({
    name: 'infrastructureAssets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfrastructureAssets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInfrastructureAssets.fulfilled, (state, action: PayloadAction<InfastructureAsset[]>) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(fetchInfrastructureAssets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default infrastructureAssetsSlice.reducer;
