import { Button, Grid } from "@mui/material"
import { AppForm, AppSelect, AppTextField } from "../../common"
import { Product } from "../../types"
import { product } from "../../validationSchema"
import { Field } from "formik"
import { useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../store/store"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchLocations } from "../../../store/slices/locationsSlice";

interface IProductAddFormProps {
    onSubmit: (values: Partial<Product>) => void;
    onCancel: () => void;
}

export const ProductAddForm: React.FC<IProductAddFormProps> = ({ onSubmit, onCancel }) => {
    const dispatch: AppDispatch = useDispatch()
    const locations: Location[] = useSelector((state: RootState) => state.locations?.data?.locations as any);
    const params = new URLSearchParams();

    useEffect(() => {
        dispatch(fetchLocations({ params }));
    }, []);

    const initialValues: Partial<Product> = { name: '', owner: '', locationID: '', currencyID: 'ee95a6d0-50cb-49d3-b040-bfbb2cef10d4' };

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={product}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            <Grid container columnSpacing={4} sx={{ width:'500px' }}>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="name"
                        as={AppTextField}
                        label="Name *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="owner"
                        as={AppTextField}
                        label="Owner *"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="locationID"
                        as={AppSelect}
                        label="Location *"
                        size="small"
                        options={locations?.map((entity: any) => { return { value: entity.id, label: entity.name } }) || []}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}