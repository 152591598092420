import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useTokenValidation } from '../hooks/useTokenValidation';

export const Protected: React.FC = () => {
  const isAuthorized = useTokenValidation();

  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};
