import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ActivityCode } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';

// Define the initial state
interface NaceCodesState {
    codes: ActivityCode[];
    loading: boolean;
    error: string | null;
    lastFetched: number | null;
}

const initialState: NaceCodesState = {
    codes: [],
    loading: false,
    error: null,
    lastFetched: null,
};

// Create an async thunk for fetching NACE codes
export const fetchNaceCodes = createAsyncThunk(
    'naceCodes/fetchNaceCodes',
    async (_, { getState, rejectWithValue }) => {
        const state = getState() as { naceCodes: NaceCodesState };
        if (state.naceCodes.codes.length > 0) {
            // If codes are already fetched, return early
            return state.naceCodes.codes;
        }

        try {
            const params = new URLSearchParams();
            params.append('type', 'NACE');
            const response = await axiosClient.General.getNaceCodes(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const naceCodesSlice = createSlice({
    name: 'naceCodes',
    initialState,
    reducers: {
        // Add reducers here if you need synchronous actions
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNaceCodes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNaceCodes.fulfilled, (state, action: PayloadAction<ActivityCode[]>) => {
                state.codes = action.payload;
                state.loading = false;
                state.lastFetched = Date.now();
            })
            .addCase(fetchNaceCodes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default naceCodesSlice.reducer;