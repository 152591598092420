import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Box, Menu, MenuItem, ListItemIcon, ListItemText, List, ListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import { UserInfo } from '../types/security';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TenantInfo } from '../types';
import { AppDispatch } from '../../store/store';
import { fetchTenantInfo, selectTenantInfo } from '../../store/slices/tenantInfoSlice';
import { AppDraggableModal } from '../common';

interface AppSignedInMenuProps {
  onSignOut: () => void;
}

export const AppSignedInMenu: React.FC<AppSignedInMenuProps> = ({ onSignOut }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userFullName, setUserFullName] = useState<string>('');
  const [notifications, setNotifications] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const tenantState = useSelector(selectTenantInfo);

  useEffect(() => {
    dispatch(fetchTenantInfo());
  }, [dispatch]);

  useEffect(() => {
    const storedUserData = localStorage.getItem('user');
    if (storedUserData) {
      const userData: UserInfo = JSON.parse(storedUserData);
      setUserFullName(`${userData.user.firstName} ${userData.user.lastName}`);
    }
  }, []);

  useEffect(() => {
    if (tenantState.data) {
      updateNotifications(tenantState.data);
    }
  }, [tenantState.data]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserFullNameClick = () => {
    handleMenuClose();
    navigate('/onboarding');
  };

  const handleNotificationsClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const updateNotifications = (tenantData: TenantInfo | null) => {
    let notificationMessages: string[] = [];
    if (tenantData) {
      if (tenantData.name === '' || tenantData.name === null) {
        notificationMessages.push('No legal name is defined.');
      }
      if (tenantData.legalEntities.length === 0) {
        notificationMessages.push('No legal entities are defined.');
      }
      if (tenantData.locations.length === 0) {
        notificationMessages.push('No locations are defined.');
      }
      if (tenantData.facilities.length === 0) {
        notificationMessages.push('No facilities are defined.');
      }
    } else {
      notificationMessages.push('Tenant data is missing.');
    }

    setNotifications(notificationMessages);
  };

  const getNotificationsCount = () => {
    return notifications.length;
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        {getNotificationsCount() > 0 && (
          <>
            <IconButton size="small" aria-label="show notifications" color="inherit" onClick={handleNotificationsClick}>
              <Badge badgeContent={getNotificationsCount()} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </>
        )}

        <IconButton
          size="small"
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleUserFullNameClick}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={userFullName} />
          </MenuItem>
          <MenuItem onClick={onSignOut}>
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </Menu>
      </Box>
      <AppDraggableModal
        title="Notifications"
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <List>
          {notifications.map((notification, index) => (
            <ListItem key={index}>
              <MuiListItemIcon>
                <NotificationsIcon fontSize="small" />
              </MuiListItemIcon>
              <MuiListItemText primary={notification} />
            </ListItem>
          ))}
        </List>
      </AppDraggableModal>
    </>
  );
};