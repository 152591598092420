import { Grid, Button, Typography } from "@mui/material";

type AppConfirmDialogProps = {
    onSubmit: () => void;
    onCancel?: () => void;
    label: string;
};

export const AppConfirmDialog:React.FC<AppConfirmDialogProps> = ({ onSubmit, onCancel, label }) => {

    return (
        <>
            <Grid sx={{ mb: 4 }}>
                <Typography variant="body2">
                    {label}
                </Typography>
            </Grid>
            <Grid container columnSpacing={2} sx={{ justifyContent: "flex-end" }}>
                <Grid item>
                        <Button color="primary" variant="outlined" size="small" onClick={onSubmit}>
                            Delete
                        </Button>
                </Grid>
                <Grid item>
                        <Button color="secondary" variant="outlined" size="small" onClick={onCancel}>
                            Cancel
                        </Button>
                </Grid>
            </Grid>
        </>
    )
}
