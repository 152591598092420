import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface AppRadioInputProps {
    name: string;
    label: string;
}

export const AppRadioInput: React.FC<AppRadioInputProps> = ({ name, label }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(name, event.target.value === 'true');
    };

    return (
        <FormControl component="fieldset" error={meta.touched && Boolean(meta.error)}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row aria-label={name} name={name} value={String(field.value)} onChange={handleChange}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            {meta.touched && meta.error ? <div style={{ color: 'red', marginTop: '5px' }}>{meta.error}</div> : null}
        </FormControl>
    );
};
