import { Box, Typography } from "@mui/material"
import { AssetListTable } from "./AssetListTable"
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchInfrastructureAssets } from "../../store/slices/infrastructureAssetsSlice";

export const Assets: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { list: infraList, loading: infraLoading, error: infraError } = useSelector((state: RootState) => state.infrastructure);

    useEffect(() => {
        if (!infraList?.length && !infraLoading) {
            const params = new URLSearchParams();
            dispatch(fetchInfrastructureAssets(params));
        }
    }, [dispatch, infraList, infraLoading]);

    
    return <>
         <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#757575' }}>
                Infrastructure Assets
            </Typography>
        </Box>
        <AssetListTable data={infraList} />
    </>
}