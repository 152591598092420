import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Country } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';

interface CountriesState {
    values: Country[];
    loading: boolean;
    error: string | null;
    lastFetched: number | null;
}

const initialState: CountriesState = {
    values: [],
    loading: false,
    error: null,
    lastFetched: null,
};

export const fetchCountries = createAsyncThunk(
    'countries/fetchCountries',
    async (_, { getState, rejectWithValue }) => {
        const state = getState() as { countries: CountriesState };
        if (state.countries.values.length > 0) {
            // If countries are already fetched, return early
            return state.countries.values;
        }

        try {
            const response = await axiosClient.General.getCountries();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountries.fulfilled, (state, action: PayloadAction<Country[]>) => {
                state.values = action.payload;
                state.loading = false;
                state.lastFetched = Date.now();
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default countriesSlice.reducer;