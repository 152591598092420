import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { LegalName } from '../../app/types';
import { RootState } from '../store';

interface TenantState {
    data: LegalName | null;
    loading: boolean;
    error: string | null;
}

const initialState: TenantState = {
    data: null,
    loading: false,
    error: null,
};

export const updateTenantName = createAsyncThunk(
    'tenant/updateTenantName',
    async (data: LegalName, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Tenant.updateName(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateTenantName.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTenantName.fulfilled, (state, action: PayloadAction<LegalName>) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(updateTenantName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default tenantSlice.reducer;
export const selectTenant = (state: RootState) => state.tenantInfo;
