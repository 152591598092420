import { Grid, IconButton } from "@mui/material"
import { Field, FieldArray, useFormikContext } from "formik"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon  from '@mui/icons-material/DeleteForever';
import { AppSelect, AppSelectWithFilter, AppTextField } from "../../common"
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCities } from "../../../store/slices/citiesSlice";

export const FacilitiesForm: React.FC = () => {

    const { values } = useFormikContext<{ locations: Location[] }>();
    const dispatch: AppDispatch = useDispatch()
    const cities = useSelector((state: RootState) => state.cities.values);

     useEffect(() => {
        const params = new URLSearchParams();
        params.append('countryID', '335d7ddf-c3cd-4e71-8ec9-f89501eb7727'); //Romania
        dispatch(fetchCities({ params  }));
    }, [dispatch]);

    if (!values.locations) {
        return <div>Loading...</div>;
    }

    return (
        <Grid container  sx={{ bgcolor: 'onboarding.light', borderRadius: '16px', p: '1rem 2rem 1rem 1rem' }}>
            <FieldArray name="locations">
                {({ push, remove }) => (
                    <>
                        {values?.locations?.map((entity, index) => (
                            <Grid container columnSpacing={4} sx={{mb:2, pl:4}} key={index}>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`locations.${index}.name`}
                                        as={AppTextField}
                                        label="Name *"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`locations.${index}.cityID`}
                                        as={AppSelectWithFilter}
                                        label="City *"
                                        options={cities.map(city => ({ value: city.id, label: city.name }))}
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{mb:2}}>
                                    <Field
                                        name={`locations.${index}.ownership`}
                                        as={AppSelect}
                                        label="Ownership *"
                                        options={[{value: "Owned", label: "Owned"}, {value: "Rented", label: "Rented"}, {value: "Other", label: "Other"}]}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {index !== 0 && (
                                        <IconButton color="error" onClick={() => remove(index)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Field
                                    name={`locations.${index}.id`}
                                    type="hidden"
                                />
                            </Grid>
                        ))}
                        <IconButton color="primary" sx={{ml:4}} onClick={() => push({ name: '', countryId:'335d7ddf-c3cd-4e71-8ec9-f89501eb7727', cityID: '', ownership: '', type: 'Facility' })}>
                            <AddCircleIcon />
                        </IconButton>
                    </>
                )}
                </FieldArray>
        </Grid>
    )
}