import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { AppNavBar, AppSideBar } from '../../app/layout';
import { Charts } from './Charts';
import { Prerequisites } from './Prerequisites';
import { Protected } from '../../app/security';
import { Ftes } from './Ftes';
import { Materials } from './Materials';
import { Assets } from './Assets';

export const Dashboard: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <AppNavBar />
      <Box sx={{ display: 'flex', pt: 8 }}>
        <AppSideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <ToastContainer />
          <Routes>
            <Route element={<Protected />}>
              <Route path="/" element={<Navigate to="/dashboard/charts" replace />} />
              <Route path="/charts" element={<Charts />} />
              <Route path="/human-resources" element={<Ftes />} />
              <Route path="/materials" element={<Materials />} />
              <Route path="/infrastructure-assets" element={<Assets />} />
              <Route path="/prerequisites" element={<Prerequisites />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </>
  );
};
