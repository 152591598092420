import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Material } from "../../app/types";

interface IMaterialListTableProps {
    data: Material[] | null;
}

export const MaterialListTable: React.FC<IMaterialListTableProps> = ({data}) => { 
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 720 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight:"bold"}}>Name</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Unit Quantity</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Unit Type</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Unit Price</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>GHG Per Unit</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Type</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Emissions</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Cost</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Supplier Id</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Process Id</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Currency Id</TableCell>
                            <TableCell sx={{fontWeight:"bold"}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((material) => (
                            <>
                                <TableRow 
                                    hover 
                                    key={material.id}
                                    sx={{ 
                                        '& > *': { borderBottom: 'unset' },
                                        '&:last-child td, &:last-child th': { border: 0}
                                    }}
                                >
                                    <TableCell align="left">{material.name}</TableCell>
                                    <TableCell align="left">{material.unitQuantity}</TableCell>
                                    <TableCell align="left">{material.unitType}</TableCell>
                                    <TableCell align="left">{material.unitPrice}</TableCell>
                                    <TableCell align="left">{material.ghgPerUnit}</TableCell>
                                    <TableCell align="left">{material.type}</TableCell>
                                    <TableCell align="left">{material.emissions}</TableCell>
                                    <TableCell align="left">{material.cost}</TableCell>
                                    <TableCell align="left">{material.supplierID}</TableCell>
                                    <TableCell align="left">{material.processID}</TableCell>
                                    <TableCell align="left">{material.currencyID}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Edit material">
                                            <IconButton color="primary" size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete material">
                                            <IconButton color="error" size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    </>)
}