import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Location, Operation, Process, Product } from '../../../app/types';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { deleteOperation, fetchProductById } from '../../../store/slices/productsSlice';
import { fetchLocations } from '../../../store/slices/locationsSlice';
import { ConstructionOutlined } from '@mui/icons-material';
import { deleteProcess } from '../../../store/slices/processesSlice';

interface IProductListTableProps {
    data: Product[];
    onAddOperation: (product: Product) => void;
    onAddProcess: (product: Operation) => void;
    onDeleteProduct: (productID: string) => void;
}

export const ProductListTable: React.FC<IProductListTableProps> = ({data, onAddOperation, onAddProcess, onDeleteProduct}) => {
    const dispatch: AppDispatch = useDispatch();
    const { selectedProduct, loading, error } = useSelector((state: RootState) => state.products);
    const locations: Location[] = useSelector((state: RootState) => state.locations?.data?.locations as any);

    const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
    const [expandedRowProcessId, setExpandedRowProcessId] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams();
        dispatch(fetchLocations({ params }));
    }, []);

    useEffect(() => {
        if(expandedRowId) dispatch(fetchProductById(expandedRowId));
    }, [expandedRowId]);

    const toggleRowExpansion = (productId: string) => {
        if (expandedRowId === productId) {
            setExpandedRowId(null);
        } else {
            setExpandedRowId(productId);
        }
    };

    const toggleProcessRowExpansion = (processId: string) => {
        if (expandedRowProcessId === processId) {
            setExpandedRowProcessId(null);
        } else {
            setExpandedRowProcessId(processId);
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 720 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight:"bold"}}>Name</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Owner</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>City</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>GHG</TableCell>
                            <TableCell sx={{fontWeight:"bold"}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((product) => (
                            <>
                                <TableRow 
                                    hover 
                                    key={product.id}
                                    sx={{ 
                                        '& > *': { borderBottom: 'unset' },
                                        '&:last-child td, &:last-child th': { border: 0},
                                        backgroundColor: expandedRowId === product.id ? '#f0f8ff' : '',
                                    }}
                                >
                                    <TableCell align="left" onClick={() => toggleRowExpansion(product.id)}>
                                        <IconButton size="small">
                                            {expandedRowId === product.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                        {product.name}
                                    </TableCell>
                                    <TableCell align="left">{product.owner}</TableCell>
                                    <TableCell align="left">{(locations?.find((location: Location) => location.id === product.locationID) as any)?.name || ''}</TableCell>
                                    <TableCell align="left">{product.emissions} kg</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Add operation">
                                            <IconButton color="primary" size="small" onClick={() => {setExpandedRowId(null); onAddOperation(product)}}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit product">
                                            <IconButton color="primary" size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete product" onClick={() => onDeleteProduct(product.id)}>
                                            <IconButton color="error" size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                {expandedRowId === product.id && selectedProduct && (
                                    selectedProduct?.operations?.map((operation: any) => (
                                        <>
                                            <TableRow key={operation.id}>
                                                <TableCell align="left"  sx={{pl:8, cursor: 'pointer'}} onClick={() => toggleProcessRowExpansion(operation.id)}>
                                                    <IconButton size="small">
                                                        {expandedRowProcessId === operation.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                    {operation.name}
                                                </TableCell>
                                                <TableCell align="left">-</TableCell>
                                                <TableCell align="left">{(locations?.find((location: Location) => location.id === operation.locationID) as any)?.name || ''}</TableCell>
                                                <TableCell align="left">{operation.emissions} kg</TableCell>
                                                <TableCell align="left">
                                                    <Tooltip title="Add Process">
                                                        <IconButton color="secondary" size="small" onClick={() => onAddProcess(operation)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit operation">
                                                        <IconButton color="secondary" size="small">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete operation" onClick={() => {setExpandedRowId(null); dispatch(deleteOperation(operation.id))}}>
                                                        <IconButton color="warning" size="small">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {expandedRowProcessId === operation.id && operation.processes.map((process: Process) => 
                                                <TableRow key={process.id}>
                                                    <TableCell align="left"  sx={{pl: 10}}>
                                                        <IconButton size="small">
                                                            <ConstructionOutlined />
                                                        </IconButton>
                                                        {process.name}
                                                    </TableCell>
                                                    <TableCell align="left">-</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">{process.emissions} kgCO2e</TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title="Delete operation" onClick={() => {setExpandedRowId(null); dispatch(deleteProcess(process.id || ''))}}>
                                                            <IconButton color="warning" size="small">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </>
                                    ))
                                )}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
