import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { fetchFTEs } from "../../store/slices/ftesSlice";
import { FteListTable } from "./FteListTable";

export const Ftes: React.FC =() => {

    const dispatch: AppDispatch = useDispatch();
    const { list: fteList, loading: infraLoading } = useSelector((state: RootState) => state.fte);

    useEffect(() => {
        if (!fteList?.length && !infraLoading) {
            const params = new URLSearchParams();
            // params.append('templates', 'true');
            dispatch(fetchFTEs(params));
        }
    }, [dispatch, fteList, infraLoading]);

    return <>
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#757575' }}>
                Human Resources
            </Typography>
        </Box>
        <FteListTable data={fteList} />
    </>
}