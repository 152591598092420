import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { config } from "../../config";
import { ActivityCode, Authentication, LegalEntity, Product, Signup, UserInfo, Location, UploadPrerequisites, IscoCode, LegalName, Operation, LegalEntities, Country, City, Locations, Material, InfastructureAsset, Process, TenantInfo, FTE, EnergySource } from "../types";

const axiosInstance = axios.create({
    baseURL: config.co2LaterURL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const isTokenExpired = (expiresAt: string | null, currentDate: Date) => {
    return expiresAt && currentDate.getTime() > new Date(expiresAt).getTime();
}

const isRefreshTokenExpired = (expiresAt: string | null, currentDate: Date) => {
    return expiresAt && currentDate.getTime() > new Date(expiresAt).getTime() - 3 * 60 * 60 * 1000;
}

const refreshToken = async (refreshToken: string) => {
    const response = await axios.post(`${config.co2LaterURL}/refresh`, { refreshToken });
    return response.data;
}

axiosInstance.interceptors.request.use(async (config: any) => {
    
    const user = localStorage.getItem('user');
    let loggedUser: UserInfo;
    if(user)
    {
        loggedUser = JSON.parse(user);

        const currentDate = new Date();

        if (isRefreshTokenExpired(loggedUser.refreshTokenExp.toString(), currentDate)) {
            localStorage.removeItem('user');
            window.location.href = '/?loginExpired=true';
            return;
        } else if (isTokenExpired(loggedUser.accessTokenExp.toString(), currentDate)) {
            const refreshedTokens = await refreshToken(loggedUser.refreshToken);
            loggedUser.accessToken = refreshedTokens.accessToken;
            loggedUser.accessTokenExp = refreshedTokens.accessTokenExp;
            localStorage.setItem('user', JSON.stringify(loggedUser));
        }

        if (loggedUser.accessToken) {
            config.headers.Authorization = `Bearer ${loggedUser.accessToken}`;
        }
    }
   
    return config;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
if (!error.response){
        toast.error(error.message)
    }else{

    const {data, status} = error.response!;
    switch(status) {
        case 400:
            if (data.errors) {
                for (const key in data.errors) {
                    toast.error(data.errors[key][0])
                }
            } else {
                toast.error(error.response?.data);
            }
            break;
        case 401:
            toast.error(error.message);
            break;
        case 403:
            toast.error('You are not allowed to do this!');
            break;
        case 404:
            toast.error(error.message);
            break;
        case 500:
            // history.push({
            //     pathname: '/server-error',
            //     state: {error: data}
            // });
            break;
        default:
            break;
    }

    return Promise.reject(error)
}}
);

const requests = {
    get: <T>(url: string, params?: URLSearchParams) => axiosInstance.get<T>(url, {params}).then(responseBody),
    post: <T>(url: string, body: {}) => axiosInstance.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosInstance.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axiosInstance.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody),
    postForm: <T>(url: string, body: {}) => axiosInstance.post<T>(url, body, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody),
    putForm: <T>(url: string, data: FormData) => axiosInstance.put<T>(url, data, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody)
}

const User = {
    signup: (data: Signup) => requests.post('signup', data),
    login: (data: Authentication) => requests.post<UserInfo>('login', data),
}

const Onboarding = {
    getLegalEntities: () => requests.get<LegalEntity[]>('legal-entity'),
    reconcileLegalEntities: (data: LegalEntities) => requests.put<LegalEntities>('legal-entity', data),
    getLocations: (params: URLSearchParams) => requests.get<Location[]>('location', params),
    reconcileLocations: (data: Locations) => requests.put<Locations>('location?type=Location', data),
    reconcileFacilities: (data: Locations) => requests.put<Locations>('location?type=Facility', data)
}

const Tenant = {
    getName: () =>  requests.get<string>('tenant/name'),
    getTenant: () =>  requests.get<TenantInfo>('tenant'),
    updateName: (data: LegalName) => requests.post<LegalName>('tenant/name', data),
}

const General = {
    getNaceCodes: (params: URLSearchParams) => requests.get<ActivityCode[]>('economic-activity-code', params),
    getIscoCodes: () => requests.get<IscoCode[]>('isco-code'),
    getCountries: () => requests.get<Country[]>('country'),
    getCities: (params: URLSearchParams) => requests.get<City[]>('city', params),
    getEnergySources: () => requests.get<EnergySource[]>('energy-source'),
}

const Upload = {
    uploadPrerequisites: (data: UploadPrerequisites) => requests.postForm('upload', data)
}

const Products = {
    getProducts: () => requests.get<Product[]>('product'),
    getProductById: (productID: string, params: URLSearchParams) => requests.get<Product>(`product/${productID}`, params),
    createProduct: (product:Partial<Product>) => requests.post<Product[]>('product', product),
    deleteProduct: (productID: string) => requests.delete<Product[]>(`product/${productID}`)
}

const Operations = {
    getOperations: () => requests.get<Operation[]>('operation'),
    createOperation: (operation:Partial<Operation>) => requests.post<Operation[]>('operation', operation),
    deleteOperation: (operationId: string) => requests.delete<Operation[]>(`operation/${operationId}`)
}

const Processes = {
    getProcesses: () => requests.get<Process[]>('process'),
    getProcessesByID: (processID: string, params: URLSearchParams) => requests.get<Process>(`process/${processID}`, params),
    createProcess: (process:Partial<Process>) => requests.post<Process[]>('process', process),
    updateProcess: (process: Partial<Process>) => requests.patch<Process[]>(`process/${process.id}`, process),
    deleteProcess: (processID: string) => requests.delete(`process/${processID}`)
}

const InfrastructureAssets = {
    getInfrastructureAssets: (params: URLSearchParams) => requests.get<InfastructureAsset[]>('infrastructure-asset', params),
    createInfrastructureAsset: (infra: InfastructureAsset) => requests.post<InfastructureAsset>('infrastructure-asset', infra),
    deleteInfra: (infraID: string) => requests.delete<void>(`infrastructure-asset/${infraID}`),
}

const Materials = {
    getMaterials: (params: URLSearchParams) => requests.get<Material[]>('material', params),
    createMaterial: (material: Material) => requests.post<Material>('material', material),
    deleteMaterial: (materialID: string) => requests.delete<void>(`material/${materialID}`),
}

const FTEs = {
    getFTEs: (params: URLSearchParams) => requests.get<FTE[]>('fte', params),
    createFTE: (fte: FTE) => requests.post<FTE>('fte', fte),
    deleteFTE: (fteID: string) => requests.delete<void>(`fte/${fteID}`),
}


const axiosClient = {
    User,
    General,
    Upload,
    Onboarding,
    Tenant,
    Products,
    Operations,
    Processes,
    InfrastructureAssets,
    Materials,
    FTEs,
}

export default axiosClient;