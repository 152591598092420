import React, { useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchProducts } from '../../store/slices/productsSlice';

// Register the chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

// Options for the bar chart including the title
const barOptions = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Sustainability KPIs', // Title text
      font: {
        size: 20,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'kgCO2e', // Label for the y-axis
        color: 'black'
      },
    },
  },
};

const doughnutOptions = {
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Product Environmental Impact (kgCO2e)',
      font: {
        size: 18,
      },
    },
  },
  aspectRatio: 1,
};

const colorSchema = [
  { backgroundColor: 'rgba(255, 99, 132, 0.6)', borderColor: 'rgba(255, 99, 132, 1)' }, // Red
  { backgroundColor: 'rgba(255, 206, 86, 0.6)', borderColor: 'rgba(255, 206, 86, 1)' }, // Yellow
  { backgroundColor: 'rgba(75, 192, 192, 0.6)', borderColor: 'rgba(75, 192, 192, 1)' }, // Green
  { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' }, // Blue
  { backgroundColor: 'rgba(153, 102, 255, 0.6)', borderColor: 'rgba(153, 102, 255, 1)' }, // Purple
  { backgroundColor: 'rgba(255, 99, 255, 0.6)', borderColor: 'rgba(255, 99, 255, 1)' }, // Pink
];

const getColor = (index: number) => {
  return colorSchema[index % colorSchema.length];
};

export const Charts: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();
  const { list: productList} = useSelector((state: RootState) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Sort products by emissions in descending order and pick the top 10
  const sortedProducts = [...productList].sort((a, b) => (b.emissions || 0) - (a.emissions || 0));
  const top10Products = sortedProducts.slice(0, 10);
  const otherProducts = sortedProducts.slice(10);

  // Sum the emissions of the remaining products
  const otherEmissions = otherProducts.reduce((sum, product) => sum + (product.emissions || 0), 0);
  
  const doughnutData = {
    labels: [...top10Products.map(product => product.name), 'Other'],
    datasets: [
      {
        label: '',
        data: [...top10Products.map(product => product.emissions || 0), otherEmissions],
        backgroundColor: productList.map((_, index) => getColor(index).backgroundColor),
        borderColor: productList.map((_, index) => getColor(index).borderColor),
        borderWidth: 1,
      },
    ],
  };

  // If there are more than 10 products, add "Other" category
  if (productList.length > 10) {
    doughnutData.labels.push('Other');
    doughnutData.datasets[0].data.push(otherEmissions);
    doughnutData.datasets[0].backgroundColor.push('rgba(201, 203, 207, 0.6)');
    doughnutData.datasets[0].borderColor.push('rgba(201, 203, 207, 1)');
  }
  
  const barData = {
    labels: sortedProducts.map(product => product.name),
    datasets: [
      {
        label: '',
        data: sortedProducts.map(product => product.emissions || 0),
        borderWidth: 1,
      },
    ],
  };

  return ( 
    productList && productList.length > 0 ?
    <Box sx={{ display: 'flex', padding: '20px' }}>
      <Box sx={{ width: '50%'}}>
        <Bar data={barData} options={barOptions} />
      </Box>
      <Box sx={{ width: '24%' }}>
        <Doughnut data={doughnutData} options={doughnutOptions} />
      </Box>
    </Box>
    :
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <Typography variant="h6" color="textSecondary">
          No data available
        </Typography>
      </Box>
    );
};