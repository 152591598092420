import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography, Button, Link } from '@mui/material';
import { toast } from 'react-toastify';
import axiosClient from '../../app/api/axiosClient';
import { AppTab } from '../../app/common';
import { Upload } from './Upload';

export const Prerequisites: React.FC = () => {
  const [infrastructureAssets, setInfrastructureAssets] = useState(() => localStorage.getItem('infrastructureAssets') || '');
  const [materials, setMaterials] = useState(() => localStorage.getItem('materials') || '');
  const [humanResources, setHumanResources] = useState(() => localStorage.getItem('humanResources') || '');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    localStorage.setItem('infrastructureAssets', infrastructureAssets);
  }, [infrastructureAssets]);

  useEffect(() => {
    localStorage.setItem('materials', materials);
  }, [materials]);

  useEffect(() => {
    localStorage.setItem('humanResources', humanResources);
  }, [humanResources]);

  const handleFileUpload = async (file: File, type: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    try {
      await axiosClient.Upload.uploadPrerequisites({ file, type });

      switch (type) {
        case 'INFRASTRUCTURE':
          setInfrastructureAssets(file.name);
          break;
        case 'MATERIALS':
          setMaterials(file.name);
          break;
        case 'HR':
          setHumanResources(file.name);
          break;
        default:
          break;
      }
      toast.success(`${type} uploaded successfully`);
    } catch (error) {
      toast.error('File upload failed. Please try again.');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleChange} aria-label="upload tabs">
        <Tab label="Infrastructure Assets" />
        <Tab label="Materials & Suppliers" />
        <Tab label="Human Resources" />
      </Tabs>
      <AppTab value={tabValue} index={0}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          * Please upload a CSV file with the following format that is provided in the
          <Link href="/assets/Infrastructure Asset Test Sample.csv" download  sx={{ ml: 1 }}>
            Infrastructure Assets Sample File
          </Link>
        </Typography>
        <Upload
          label="Upload Infrastructure Assets"
          fileType="INFRASTRUCTURE"
          fileName={infrastructureAssets}
          handleFileUpload={handleFileUpload}
        />
      </AppTab>
      <AppTab value={tabValue} index={1}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          * Please upload a CSV file with the following format that is provided in the
          <Link href="/assets/Materials & Suppliers Test Sample.csv" download  sx={{ ml: 1 }}>
            Materials & Suppliers Sample File
          </Link>
        </Typography>
        <Upload
          label="Upload Materials & Suppliers"
          fileType="MATERIALS"
          fileName={materials}
          handleFileUpload={handleFileUpload}
        />
      </AppTab>
      <AppTab value={tabValue} index={2}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          * Please upload a CSV file with the following format that is provided in the
          <Link href="/assets/Human Resources Test Sample.csv" download  sx={{ ml: 1 }}>
            Human Resources Sample File
          </Link>
        </Typography>
        <Upload
          label="Upload Human Resources"
          fileType="HR"
          fileName={humanResources}
          handleFileUpload={handleFileUpload}
        />
      </AppTab>
    </Box>
  );
};