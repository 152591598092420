import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { AppNavBar, AppSideBar } from '../../app/layout';
import { OrganizationalDetails } from './OrganizationalDetails';
import { Protected } from '../../app/security';

export const Onboarding: React.FC = () => {
  return (
    <>
      {/* <AppNavBar />
      <ToastContainer />
      <Divider sx={{ mb: 2 }} />
      <Box display="flex" flexDirection="row" sx={{ marginX: 'auto', maxWidth: 'lg', width: '100%', pt:8 }}>
        <Box sx={{ width: '20%', minWidth: '200px', marginRight: '2rem', mt:1 }}>
          <AppStepper steps={steps} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route element={<Protected />}>
              <Route path="/" element={<Navigate to="/onboarding/organizationaldetails" replace />} />
              <Route path="/organizationaldetails" element={<OrganizationalDetails />} />
              <Route path="/framework" element={<Framework />} />
              <Route path="/reportingformat" element={<ReportingFormat />} />
              <Route path="/esgdetails" element={<EsgDetails />} />
              <Route path="/requirements" element={<Requirements />} />
            </Route>
          </Routes>
        </Box>
      </Box> */}
      <CssBaseline />
      <AppNavBar />
      <Box sx={{ display: 'flex', pt: 8 }}>
        <AppSideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <ToastContainer />
          <Routes>
            <Route element={<Protected />}>
              <Route path="/" element={<Navigate to="/onboarding/organizationaldetails" replace />} />
              <Route path="/organizationaldetails" element={<OrganizationalDetails />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </>
  );
};