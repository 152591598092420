import { Button, Grid } from "@mui/material"
import { Field, FieldArray, useFormikContext } from "formik"
import { AppSelect, AppTextField } from "../../common"
import { SubProcess } from "../../types";
import { AddCircleOutline, DeleteForeverOutlined } from "@mui/icons-material";

export const ConsumableEditForm: React.FC<any> = () => {
    const { values } = useFormikContext<SubProcess>();

    return (
        <>
            <FieldArray name="consumables">
                {({ push, remove }) => (
                    <>
                        {values?.consumables?.map((entity, index) => (
                            <Grid container columnSpacing={4} sx={{my:5}} key={`rawMaterial${index}`}>
                                <Grid item xs={6} sx={{mb:2}}>
                                    <Field
                                        name={`consumables.${index}.name`}
                                        as={AppTextField}
                                        label="Name"
                                        size="small"
                                    />        
                                </Grid>
                                <Grid item xs={6} sx={{mb:2}}>
                                    <Field
                                        name={`consumables.${index}.unitType`}
                                        as={AppSelect}
                                        label="Unit Type"
                                        size="small"
                                        options={[{value: 'kilograms', label: 'kilograms'}, {value: 'grams', label: 'grams'}, {value: 'liters', label: 'liters'}, {value: 'm3', label: 'm3'}, {value: 'piece', label: 'piece'}]}
                                    />        
                                </Grid>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`consumables.${index}.unitQuantity`}
                                        as={AppTextField}
                                        label="Unit Quantity"
                                        size="small"
                                    />        
                                </Grid>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`consumables.${index}.unitPrice`}
                                        as={AppTextField}
                                        label="Unit Price"
                                        size="small"
                                    />        
                                </Grid>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`consumables.${index}.co2Unit`}
                                        as={AppTextField}
                                        label="CO2/Unit"
                                        size="small"
                                    />        
                                </Grid>
                                
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button color="primary" onClick={() => remove(index)}>
                                        <DeleteForeverOutlined sx={{ marginRight: 1 }} />
                                        Remove consumabile
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                        <Button color="primary" sx={{ mt: 2 }} onClick={() => push({ name: '', unitQuantity: '', unitType: '', unitPrice: '', co2Unit: '' })}>
                            <AddCircleOutline sx={{ marginRight: 1 }} />
                            Add consumabile
                        </Button> 
                    </>
                )}
                </FieldArray>
        </>
    )
}