import { updateTenantName } from "../../store/slices/tenantSlice";
import { LegalEntities, LegalName, Locations } from "../../app/types";
import { reconcileLegalEntities } from "../../store/slices/legalEntitiesSlice";
import { reconcileLocations } from "../../store/slices/locationsSlice";
import { reconcileFacilities } from "../../store/slices/facilitiesSlice";

type PayloadTransformer = (values: any) => any;

interface FormActionConfig {
    action: (payload: any) => any;
    transformer: PayloadTransformer;
}

export const formActionsMapping: Record<string, FormActionConfig> = {
    legalName: {
        action: updateTenantName,
        transformer: (values: any) => ({ name: values.name } as LegalName)
    },
    legalEntities: {
        action: reconcileLegalEntities,
        transformer: (values: any) => {
            const legalEntities = values.legalEntities.map((entity: any) => ({
                id: entity.id,
                name: entity.name,
                type: entity.type,
                economicActivityCodeIDs: entity.economicActivityCodeIDs,
            }));
            return { legalEntities } as LegalEntities;
        }
    },
    locations: {
        action: reconcileLocations,
        transformer: (values: any) => {
            const locations = values.locations.map((entity: any) => ({
                id: entity.id,
                cityID: entity.cityID,
                name: entity.name,
                ownership: entity.ownership,
            }));
            return { locations } as Locations;
        }
    },
    facilities: {
        action: reconcileFacilities,
        transformer: (values: any) => {
            const locations = values.locations.map((entity: any) => ({
                id: entity.id,
                cityID: entity.cityID,
                name: entity.name,
                ownership: entity.ownership,
            }));
            return { locations } as Locations;
        }
    },
};
