import { configureStore } from '@reduxjs/toolkit';
import naceCodesReducer from './slices/naceCodesSlice';
import productsReducer from './slices/productsSlice';
import legalEntitiesReducer from './slices/legalEntitiesSlice';
import locationsReducer from './slices/locationsSlice';
import iscoCodesReducer from './slices/iscoCodesSlice';
import tenantReducer from './slices/tenantSlice';
import facilitiesReducer from './slices/facilitiesSlice';
import processesReducer from './slices/processesSlice';
import infrastructureAssetsReducer from './slices/infrastructureAssetsSlice';
import materialsReducer from './slices/materialsSlice';
import countriesReducer from './slices/countriesSlice';
import citiesReducer from './slices/citiesSlice';
import fteReducer from './slices/ftesSlice';
import energySourcesReducer from './slices/energySourcesSlice';
import tenantInfoReducer from './slices/tenantInfoSlice';

export const store = configureStore({
    reducer: {
        naceCodes: naceCodesReducer,
        legalEntities: legalEntitiesReducer,
        locations: locationsReducer,
        iscoCodes: iscoCodesReducer,
        tenant: tenantReducer,
        tenantInfo: tenantInfoReducer,
        facilities: facilitiesReducer,
        products: productsReducer,
        processes: processesReducer,
        infrastructure: infrastructureAssetsReducer,
        materials: materialsReducer,
        countries: countriesReducer,
        cities: citiesReducer,
        fte: fteReducer,
        energySources: energySourcesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
