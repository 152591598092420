import React from 'react';
import { List, Drawer, Box } from "@mui/material";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TransformIcon from '@mui/icons-material/Transform';
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InventoryIcon from '@mui/icons-material/Inventory';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { AppCustomLink } from '../common';

export const AppSideBar: React.FC = () => {
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': { 
                    width: 240, 
                    boxSizing: 'border-box', 
                    top: '64px'
                },
            }}
        >
            <Box sx={{ overflow: 'auto', pl:1 }}>
                <List>
                    <AppCustomLink to="/onboarding/organizationaldetails" icon={<AppRegistrationIcon />} label="Onboarding" />
                    <AppCustomLink to="/dashboard/charts" icon={<BarChartIcon />} label="Dashboard" />
                    <AppCustomLink to="/dashboard/prerequisites" icon={<CloudUploadIcon />} label="Prerequisites" />
                    <List sx={{ pl: 2 }}>
                        <AppCustomLink to="/dashboard/infrastructure-assets" icon={<AnalyticsIcon />} label="Assets" />
                        <AppCustomLink to="/dashboard/materials" icon={<InventoryIcon />} label="Materials" />
                        <AppCustomLink to="/dashboard/human-resources" icon={<SupervisorAccountIcon />} label="Human Res." />
                    </List>
                    <AppCustomLink to="/valuechain" icon={<PrecisionManufacturingIcon />} label="Value Chain" exact />
                    <List sx={{ pl: 2 }}>
                        <AppCustomLink to="/valuechain/products" icon={<CategoryIcon />} label="Products" />
                        <AppCustomLink to="/valuechain/process-templates" icon={<TransformIcon />} label="Processes" />
                    </List>
                    <AppCustomLink to="/valuechain/settings" icon={<SettingsIcon />} label="Settings" />
                    <AppCustomLink to="/valuechain/generatesps" icon={<AddToDriveIcon />} label="Generate SPS" />
                </List>
            </Box>
        </Drawer>
    );
};
