import { Button, Grid } from "@mui/material";
import { AppForm, AppSelect, AppTextField } from "../../../common";
import { Material } from "../../../types";
import { infraAssetCreateSchema, materialCreateSchema } from "../../../validationSchema";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchMaterials } from "../../../../store/slices/materialsSlice";

interface IProcessMaterialFormProps {
    onSubmit: (values: Material) => void;
    onCancel: () => void;
}

export const ProcessMaterialForm: React.FC<IProcessMaterialFormProps> = ({ onSubmit, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();
    const { list: materialsList, loading: infraLoading, error: materialError } = useSelector((state: RootState) => state.materials);

    useEffect(() => {
        if (!materialsList?.length && !infraLoading) {
            const params = new URLSearchParams();
            params.append('templates', 'true');
            dispatch(fetchMaterials(params));
        }
    }, [dispatch, materialsList]);

    useEffect(() => {
        if (materialError) {
            toast.error(`Failed to load infrastructure assets: ${materialError}`);
        }
    }, [materialError]);

    const initialValues: {materialID: string, unitQuantity: string} = { materialID: '', unitQuantity: '' };

    const materialOptions = materialsList?.map((ic: Material) => ({
        value: ic.id,
        label: `\u200B${ic.name} - ${ic.type}`,
    }));

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={materialCreateSchema}
            onSubmit={(values: {materialID: string, unitQuantity: string}) => {
                const material: any = {...materialsList?.find((ic: Material) => ic.id === values.materialID)};

                if(!material) return;

                material.unitQuantity = values.unitQuantity;
                onSubmit(material);
            }}
        >
            <Grid container columnSpacing={4}>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="materialID"
                        as={AppSelect}
                        label="Material"
                        size="small"
                        options={materialOptions}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="unitQuantity"
                        type="number"
                        as={AppTextField}
                        label="Unit Quantity"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Add Material</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}