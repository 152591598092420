import React from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIconProps } from "@mui/material";

interface IAppCustomLinkProps {
    to: string;
    icon: React.ReactElement<SvgIconProps>;
    label: string;
    exact?: boolean;
}

export const AppCustomLink: React.FC<IAppCustomLinkProps> = ({ to, icon, label, exact = false }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: exact });

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={NavLink}
                to={to}
                style={{
                    backgroundColor: match ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                }}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
            </ListItemButton>
        </ListItem>
    );
};
