import React from 'react';
import { TextField } from '@mui/material';

interface IProductListSearchFilterProps {
    onFilterChange: (query: string) => void;
}

export const ProductListSearchFilter: React.FC<IProductListSearchFilterProps> = ({ onFilterChange }) => {
    return (
        <TextField
            label="Search"
            variant="outlined"
            fullWidth
            onChange={(e: { target: { value: string; }; }) => onFilterChange(e.target.value)}
            margin="normal"
            size="small"
        />
    );
};
