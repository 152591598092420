import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { Location, Locations } from '../../app/types';

interface LocationsState {
    data: Locations | null;
    loading: boolean;
    error: string | null;
}

interface FetchLocationsParams {
    params: URLSearchParams;
}

const initialState: LocationsState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchLocations = createAsyncThunk(
    'locations/fetchLocations',
    async ({ params }: FetchLocationsParams, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Onboarding.getLocations(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const reconcileLocations = createAsyncThunk(
    'locations/reconcileLocations',
    async (data: Locations, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Onboarding.reconcileLocations(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLocations.fulfilled, (state, action: PayloadAction<Location[]>) => {
                state.data = { locations: action.payload.length > 0 ? action.payload : [{ id: "", name: "", cityID: "", ownership: "" } as Location] };
                state.loading = false;
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(reconcileLocations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reconcileLocations.fulfilled, (state, action: PayloadAction<Locations>) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(reconcileLocations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default locationsSlice.reducer;
