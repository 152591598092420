import { Grid, Typography } from "@mui/material";
import { Field } from "formik";
import { AppSelect, AppTextField } from "../../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useMemo } from "react";
import { Location } from "../../../types";

export const ProcessDetailsForm: React.FC = () => {
    const locations: Location[] = useSelector((state: RootState) => state.locations?.data?.locations as any);
    const { codes: iscoCodes, loading: iscoLoading, error: iscoError } = useSelector((state: RootState) => state.iscoCodes);

    const iscoOptions = useMemo(() => iscoCodes?.map(ac => ({
        value: ac.id,
        label: `\u200B${ac.code} - ${ac.description}`,
    })), [iscoCodes]);

    const locationOptions = useMemo(() => locations?.map((ac: Location) => ({
        value: ac.id,
        label: `\u200B${ac.name}`,
    })), [iscoCodes]);

    return (
        <Grid container columnSpacing={4} sx={{ p: '1rem 1rem 1rem 1rem', width:'100%', flex: 1 }}>
            <Grid item xs={12} sx={{mb:2}}>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Process Name
                </Typography>
                <Field
                    name="name"
                    as={AppTextField}
                    label="Process Name *"
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sx={{mb:2}}>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Location
                </Typography>
                <Field
                    name="locationID"
                    as={AppSelect}
                    label="Location"
                    size="small"
                    options={locationOptions}
                />
            </Grid>

        </Grid>
    )
}