import { Grid } from "@mui/material"
import { Field } from "formik"
import { AppTextField } from "../../common"

export const LegalNameForm: React.FC = () => {
    return (
        <Grid container columnSpacing={4} sx={{ bgcolor: 'onboarding.light', borderRadius: '16px', p: '1rem 2rem 1rem 1rem', ml:'0px', width:'100%' }}>
            <Grid item xs={6} sx={{mb:2}}>
                <Field
                    name="name"
                    as={AppTextField}
                    label="Name *"
                    size="small"
                />
            </Grid>
        </Grid>
    )
}