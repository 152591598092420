import React from 'react';
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface IAppTabNavigationButtonsProps {
    onBack: () => void;
    onNext: () => void;
    isNextDisabled: boolean;
    isBackDisabled: boolean;
}

export const AppTabNavigationButtons: React.FC<IAppTabNavigationButtonsProps> = ({ onBack, onNext, isNextDisabled, isBackDisabled }) => {
    return (
        <>
            <IconButton onClick={onBack} disabled={isBackDisabled} color="secondary" sx={{mr: 2}}>
                <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={onNext} disabled={isNextDisabled} color="primary">
                <ArrowForwardIcon />
            </IconButton>
        </>
    );
};
