import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { AppDispatch } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { AddCircleOutline } from "@mui/icons-material";
import { FTE, InfastructureAsset, Material, Process } from "../../../types";
import { ProcessInfraAssetsForm } from "./ProcessInfraAssetsForm";
import { createInfrastructureAssets, deleteInfrastructure } from "../../../../store/slices/infrastructureAssetsSlice";
import { ProcessAssetsTable } from "../../../layout/valuechain/process/ProcessAssetsTable";
import { tableMapping } from "../../../utils/tableMapping";
import { ProcessMaterialForm } from "./ProcessMaterialForm";
import { createMaterial, deleteMaterial } from "../../../../store/slices/materialsSlice";
import { ProcessFTEForm } from "./ProcessFTEForm";
import { createFTE, deleteFTE } from "../../../../store/slices/ftesSlice";

interface IProcessDynamicFieldsProps {
    process: Process;
}

type ViewKey = 'infra' | 'materials' | 'consumable' | 'fte' | 'safety' | null;

export const ProcessDynamicFields: React.FC<IProcessDynamicFieldsProps> = ({ process }) => {
    const dispatch: AppDispatch = useDispatch();
    const [formView, setFormView] = useState<ViewKey>(null);

    const submitInfraAsset = (infraAsset: InfastructureAsset) => {
        infraAsset.processID = process.id;
        setFormView(null);
        dispatch(createInfrastructureAssets(infraAsset));
    }

    const submitMaterial = (material: Material) => {
        material.processID = process.id;
        setFormView(null);
        dispatch(createMaterial(material));
    }

    const submitFTE = (fte: FTE) => {
        fte.processID = process.id;
        setFormView(null);
        dispatch(createFTE(fte));
    }

    const deleteMaterialByID = (materialID: string) => {
        dispatch(deleteMaterial({materialID, processID: process?.id}));
    }

    const deleteInfrastructureByID = (infraID: string) => {
        dispatch(deleteInfrastructure({infraID, processID: process?.id || ''}));
    }

    const deleteFTEByID = (fteID: string) => {
        dispatch(deleteFTE({fteID, processID: process?.id}));
    }

    return (
        <Grid container columnSpacing={4} sx={{ p: '1rem 1rem 1rem 1rem', width:'700px', flex: 1 }}>
            <Grid item xs={12}>
                <Button variant="outlined" color="primary" size="small" onClick={() => setFormView('infra')} sx={{ mb: 2, py: 1 }}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add Infrastructure
                </Button>
            </Grid>
            
            {formView === 'infra' && (
                <Grid item xs={12}>
                    <ProcessInfraAssetsForm
                        onSubmit={(infraAsset: InfastructureAsset) => submitInfraAsset(infraAsset)}
                        onCancel={() => setFormView(null)}
                    /> 
                </Grid>            
            )}
            {process.infrastructureAssets && process.infrastructureAssets.length > 0 ? (
                <Grid item xs={12}>
                    <ProcessAssetsTable data={process.infrastructureAssets || []} tableMapping={tableMapping.infrastructureTable.fieldMapping} onDelete={(infraID: string) => deleteInfrastructureByID(infraID)} />
                </Grid>
            ) : null}
            

            <Grid item xs={12}>
                <Button variant="outlined" color="primary" size="small" onClick={() => setFormView('materials')} sx={{ mb: 2, py: 1 }}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add Materials
                </Button>
            </Grid>

            {formView === 'materials' && (
                <Grid item xs={12}>
                    <ProcessMaterialForm
                        onSubmit={(material: Material) => submitMaterial(material)}
                        onCancel={() => setFormView(null)}
                    /> 
                </Grid>            
            )}

            {process.materials && process.materials.length > 0 ? (
                <Grid item xs={12}>
                    <ProcessAssetsTable data={process.materials || []} tableMapping={tableMapping.materialsTable.fieldMapping} onDelete={(materialID: string) => deleteMaterialByID(materialID)} />
                </Grid>
            ) : null}

            <Grid item xs={12}>
                <Button variant="outlined" color="primary" size="small" onClick={() => setFormView('fte')} sx={{ mb: 2, py: 1 }}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add human resource
                </Button>
            </Grid>

            {formView === 'fte' && (
                <Grid item xs={12}>
                    <ProcessFTEForm
                        onSubmit={(fte: FTE) => submitFTE(fte)}
                        onCancel={() => setFormView(null)}
                    /> 
                </Grid>            
            )}

            {process.ftes && process.ftes.length > 0 ? (
                <Grid item xs={12}>
                    <ProcessAssetsTable data={process.ftes || []} tableMapping={tableMapping.ftesTable.fieldMapping} onDelete={(fteID: string) => deleteFTEByID(fteID)} />
                </Grid>
            ) : null}

            
        </Grid>
    )
}