import { Grid, IconButton } from "@mui/material";
import { Field, useFormikContext, FieldArray } from "formik";
import { AppSelect, AppTextField, AppSelectMultiple } from "../../common";
import { LegalEntities } from '../../types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../store/store";
import { fetchNaceCodes } from "../../../store/slices/naceCodesSlice";

export const LegalEntitiesForm = () => {
    const { values } = useFormikContext<LegalEntities>();
    const dispatch: AppDispatch = useDispatch();
    const { codes, loading, error } = useSelector((state: RootState) => state.naceCodes);

    useEffect(() => {
        if (!codes.length && !loading) {
            dispatch(fetchNaceCodes());
        }
    }, [dispatch, codes, loading]);

    useEffect(() => {
        if (error) {
            toast.error(`Failed to load NACE codes: ${error}`);
        }
    }, [error]);

    const codeOptions = codes.map(ac => ({
        value: ac.id,
        label: `\u200B${ac.code} - ${ac.description}`,
    }));

    return (
        <Grid container  sx={{ bgcolor: 'onboarding.light', borderRadius: '16px', p: '1rem 2rem 1rem 1rem'}}>
            <FieldArray name="legalEntities">
                {({ push, remove }) => (
                    <>
                        {values.legalEntities?.map((entity, index) => (
                            <Grid container columnSpacing={4} sx={{mb:2, pl:4}} key={index}>
                                <Grid item xs={4}>
                                    
                                    <Field
                                        name={`legalEntities.${index}.name`}
                                        as={AppTextField}
                                        label="Name *"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{mb:2}}>
                                    <Field
                                        name={`legalEntities.${index}.type`}
                                        as={AppSelect}
                                        label="Type *"
                                        options={[
                                            { value: 'COMPANY', label: "Company" },
                                            { value: 'BRANCH', label: "Branch" },
                                            { value: 'SUBSIDIARY', label: "Subsidiary" },
                                            { value: 'OTHER', label: "Other" }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{mb:2}}>
                                    <Field
                                        name={`legalEntities.${index}.economicActivityCodeIDs`}
                                        as={AppSelectMultiple}
                                        label="NACE/CAEN Codes *"
                                        options={codeOptions}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {index !== 0 && (
                                        <IconButton color="error" onClick={() => remove(index)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Field
                                    name={`legalEntities.${index}.id`}
                                    type="hidden"
                                />
                            </Grid>
                        ))}
                        <IconButton color="primary" sx={{ ml:4 }} onClick={() => push({name: '', type: '', economicActivityCodeIDs: [] })}>
                            <AddCircleIcon />
                        </IconButton>
                    </>
                )}
            </FieldArray>
        </Grid>
    );
};
