import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { ProductListSearchFilter } from './ProductListSearchFilter';
import { Process } from '../../../app/types';
import { Add } from '@mui/icons-material';
import { ProcessListTable } from './ProcessListTable';
import { AppDraggableModal } from '../../../app/common';
import { ProcessAddTemplateForm } from '../../../app/forms';
import { AppDispatch, RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchProcesses, createProcess } from '../../../store/slices/processesSlice';


type ModalKey = 'addProcess';

export const ValueChainProcessTemplates: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [filterQuery, setFilterQuery] = useState('');
    const { list, loading, error } = useSelector((state: RootState) => state.processes);
    const [modalStates, setModalStates] = useState<{[key in ModalKey]: { isOpen: boolean; data: Process | null }}>({
        addProcess: { isOpen: false, data: null },
    });

    useEffect(() => {
        dispatch(fetchProcesses());
    }, []);

    const toggleModal = (modalKey: ModalKey, data: Process | null = null) => {
        setModalStates(prev => {
            const isOpen = prev[modalKey].isOpen;
            return {
                ...prev,
                [modalKey]: { isOpen: !isOpen, data },
            };
        });
    };

    const handleSubmit = (data: Partial<Process>) => {
        dispatch(createProcess(data));
        toggleModal('addProcess');
    };

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#757575' }}>
                    Process templates
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <ProductListSearchFilter onFilterChange={setFilterQuery} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button startIcon={<Add />} variant="outlined" onClick={() => toggleModal('addProcess')} sx={{ ml: 1 }}>
                            New Process Template
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            
            <ProcessListTable data={list} />

            <AppDraggableModal
                title={'Add New Process'}
                open={modalStates.addProcess.isOpen}
                onClose={() => toggleModal('addProcess')}
            >
                <ProcessAddTemplateForm
                    onSubmit={(data: Partial<Process>) => handleSubmit(data)}
                    onCancel={() => toggleModal('addProcess')} 
                />
            </AppDraggableModal>
        </>
    );
};
