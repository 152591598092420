import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { AppSignedInMenu } from "./AppSignedInMenu";

export const AppNavBar: React.FC = () => {
    const isAuthenticated = !!localStorage.getItem('user');

    const handleSignOut = () => {
        localStorage.removeItem('user');
        window.location.href = '/login';
    };

    return  (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="success">
                <Toolbar>
                    <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' }, ml:1 }}
                    >
                        CO2Later
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {isAuthenticated && <AppSignedInMenu onSignOut={handleSignOut} />}
                </Toolbar>
            </AppBar>
        </Box>
    )
}