import * as Yup from "yup";

const generalDisclosureSchema = Yup.object({
    name: Yup.string(),
    location: Yup.string(),
    reqOmitted: Yup.string(),
    reason: Yup.string(),
    explanation: Yup.string(),
});

export const generalDisclosures = Yup.object({
    disclosures: Yup.array().of(generalDisclosureSchema)
});

const materialTopicSchema = Yup.object({
    name: Yup.string(),
    location: Yup.string()
});

export const materialTopics = Yup.object({
    materialTopics: Yup.array().of(materialTopicSchema)
});

const topicDisclosureSchema = Yup.object({
    name: Yup.string(),
    location: Yup.string(),
    reqOmitted: Yup.string(),
    reason: Yup.string(),
    explanation: Yup.string(),
});

export const topicDisclosures = Yup.object({
    topicDisclosures: Yup.array().of(topicDisclosureSchema)
});

export const statementOfUse = Yup.object({
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    name: Yup.string(),
});

export const notifyGRI = Yup.object({
    isNotified: Yup.bool()
});

export const publishGRIIndex = Yup.object({
    publishOn: Yup.array(),
});