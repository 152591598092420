import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleResetPassword = (event: React.FormEvent) => {
    event.preventDefault();
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <Paper elevation={10} sx={{
        padding: '40px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        minWidth: '320px',
      }}>
        <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
          Reset Your Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleResetPassword}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          <TextField
            label="Email Address"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            autoComplete="email"
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Send Reset Link
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};