import * as Yup from "yup";

export const operation = Yup.object({
    name: Yup.string().required("Name is required"),
    locationID: Yup.string().required("Location is required"),
    currencyID: Yup.string().required("Currency is required"),
});

export const subProcess = Yup.object({
    subProcesses: Yup.string().required("Sub process selection required"),
});