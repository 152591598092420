import React from 'react';
import { Button, Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppForm, AppTextField } from '../../app/common';
import { Signup } from '../../app/types/';
import { signupValidationSchema } from './signupValidationSchema';
import { Field } from 'formik';
import axiosClient from '../../app/api/axiosClient';
import { toast, ToastContainer } from 'react-toastify';

export const SignUp: React.FC = () => {

  const navigate = useNavigate();

  const initialValues: Signup = { firstName: '', lastName: '', email: '', password: '', passwordConfirmation: '' };

  const signup = async (values: Signup) => {
    try {
      await axiosClient.User.signup(values);

      navigate('/login');
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <ToastContainer />
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <Paper elevation={12} sx={{
        padding: '40px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        minWidth: '320px',
      }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sign Up
        </Typography>
        <AppForm initialValues={initialValues} validationSchema={signupValidationSchema} onSubmit={(values) => signup(values)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              width: '100%',
            }}
          >
             <Field
                name="firstName"
                as={AppTextField}
                label="First Name *"
                size="small"
              />
              <Field
                name="lastName"
                as={AppTextField}
                label="Last Name *"
                size="small"
              />
              <Field
                name="email"
                as={AppTextField}
                label="Email *"
                size="small"
              />
              <Field
                name="password"
                as={AppTextField}
                label="Password *"
                size="small"
                type="password"
              />
              <Field
                name="passwordConfirmation"
                as={AppTextField}
                label="Password Confirmation *"
                size="small"
                type="password"
              />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Signup
            </Button>
          </Box>
        </AppForm>
      </Paper>
    </Box>
    </>
  );
};