import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Login, Onboarding, PasswordReset, SignUp, ValueChain, Dashboard } from './features';
import { Protected } from './app/security';

declare module '@mui/material/styles' {
  interface Palette {
    custom: Palette['primary'];
    onboarding: Palette['primary'];
  }

  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
    onboarding?: Palette['primary'];
  }
}

const theme = createTheme({
  palette: {
    onboarding: {
      light: '#fafafa',
      main: '#fafafa',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route element={<Protected />}>
              <Route path="/onboarding/*" element={<Onboarding />} />
              <Route path="/valuechain/*" element={<ValueChain />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
            </Route>
          </Routes>
        </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
