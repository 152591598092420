import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { FTE, Material } from '../../app/types';
import { toast } from 'react-toastify';
import { fetchProcessByID } from './processesSlice';

interface Materialstate {
    list: FTE[] | null;
    loading: boolean;
    error: string | null;
}

const initialState: Materialstate = {
    list: [],
    loading: false,
    error: null,
};

export const fetchFTEs = createAsyncThunk(
    'ftes/fetchFTEs',
    async (params: URLSearchParams, { rejectWithValue }) => {
        try {
            const response = await axiosClient.FTEs.getFTEs(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const createFTE = createAsyncThunk(
    'ftes/createFTE',
    async (material: FTE, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosClient.FTEs.createFTE(material);
            dispatch(fetchProcessByID(material.processID || ''));
            return response;
        } catch (error: any) {
            toast.error('There was a problem adding a material');
            return rejectWithValue(error.message);
        }
    }
);

export const deleteFTE = createAsyncThunk(
    'ftes/deleteFTE',
    async ({ fteID, processID }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosClient.FTEs.deleteFTE(fteID);
            dispatch(fetchProcessByID(processID));
            return response;
        } catch (error: any) {
            toast.error('There was a problem deleting a material');
            return rejectWithValue(error.message);
        }
    }
);

const materials = createSlice({
    name: 'ftes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFTEs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFTEs.fulfilled, (state, action: PayloadAction<FTE[]>) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(fetchFTEs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default materials.reducer;
