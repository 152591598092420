import React from 'react';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface IProuctListActions {
    addNewProduct: () => void;
}

export const ProductListActions: React.FC<IProuctListActions> = ({addNewProduct}) => {
    return (
        <Box>
            <Button startIcon={<AddIcon />} variant="outlined" onClick={addNewProduct}>
                New Product
            </Button>
        </Box>
    );
};
