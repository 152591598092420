import { Button, Grid } from "@mui/material";
import { AppForm, AppSelect, AppTextField } from "../../../common";
import { InfastructureAsset } from "../../../types";
import { infraAssetCreateSchema, product } from "../../../validationSchema";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchInfrastructureAssets } from "../../../../store/slices/infrastructureAssetsSlice";
import { toast } from "react-toastify";

interface IProcessInfraAssetsFormProps {
    onSubmit: (values: InfastructureAsset) => void;
    onCancel: () => void;
}

export const ProcessInfraAssetsForm: React.FC<IProcessInfraAssetsFormProps> = ({ onSubmit, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();
    const { list: infraList, loading: infraLoading, error: infraError } = useSelector((state: RootState) => state.infrastructure);

    useEffect(() => {
        if (!infraList?.length && !infraLoading) {
            const params = new URLSearchParams();
            params.append('templates', 'true');
            dispatch(fetchInfrastructureAssets(params));
        }
    }, [dispatch, infraList]);

    useEffect(() => {
        if (infraError) {
            toast.error(`Failed to load infrastructure assets: ${infraError}`);
        }
    }, [infraError]);

    const initialValues: {infrastructureID: string, duration: string} = { infrastructureID: '', duration: '' };

    const infraOptions = infraList?.map((ic: InfastructureAsset) => ({
        value: ic.id,
        label: `\u200B${ic.name}`,
    }));

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={infraAssetCreateSchema}
            onSubmit={(values: {infrastructureID: string, duration: string}) => {
                const infraAsset: any = {...infraList?.find((ic: InfastructureAsset) => ic.id === values.infrastructureID)};

                if(!infraAsset) return;

                infraAsset.duration = values.duration;
                onSubmit(infraAsset);
            }}
        >
            <Grid container columnSpacing={4}>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="infrastructureID"
                        as={AppSelect}
                        label="Infrastructure asset"
                        size="small"
                        options={infraOptions}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="duration"
                        as={AppTextField}
                        label="Duration"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Add Infrastructure</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}