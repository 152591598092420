import React from 'react';
import { Box } from '@mui/material';

type TabProps = {
  index: any;
  value: any;
  children?: React.ReactNode;
};

export const AppTab: React.FC<TabProps> = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel"
      id={`simple-tab-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ display: value === index ? 'block' : 'none' }} 
      {...other}
    >
        <Box sx={{ p: 3 }}>
            {children}
        </Box>
    </div>);
};