import { Button, Grid } from "@mui/material"
import { AppForm, AppSelect, AppTextField } from "../../common"
import { InfastructureAsset } from "../../types"
import { infraAsset } from "../../validationSchema"
import { Field } from "formik"

interface IInfrastructureFormProps {
    onSubmit: (values: InfastructureAsset) => void;
    onCancel: () => void;
}

export const InfraStructureForm: React.FC<IInfrastructureFormProps> = ({ onSubmit, onCancel }) => {
    const initialValues: InfastructureAsset = {
        id: '',
        type: '',
        name: '',
        location: '',
        energySource: '',
        consumption: '',
        cost: 0,
        co2Emissions: '',
        manufacturer: '',
        manufacturerSerialNumber: '',
        assetTypeIT: '',
        locationType: '',
        safetyKit: '',
    };

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={infraAsset}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            <Grid container columnSpacing={4} sx={{ width:'500px' }}>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="type"
                        as={AppSelect}
                        label="Asset Type"
                        size="small"
                        options={[{value: "Manufacturing", label: "Manufacturing"}, {value: "Transportation", label: "Transportation"}, {value: "IT", label: "IT"}]}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field 
                        name="name"
                        as={AppTextField}
                        label="Asset name"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="location"
                        as={AppTextField}
                        label="Asset location"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{mb:2}}>
                    <Field
                        name="energySource"
                        as={AppSelect}
                        label="Energy Source"
                        size="small"
                        options={[{value: "Electric", label: "Electric"}, {value: "Diesel", label: "Diesel"}, {value: "Petrol", label: "Petrol"}, {value: "Gas", label: "Gas"}]}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field 
                        name="consumption"
                        as={AppTextField}
                        label="Energy consumption / hour"
                        size="small"
                        type={`number`}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field 
                        name="cost"
                        as={AppTextField}
                        label="Energy Cost"
                        size="small"
                        type={`number`}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="assetTypeIT"
                        as={AppSelect}
                        label="Asset Type"
                        size="small"
                        options={[{value: "Hardware", label: "Hardware"}, {value: "Software", label: "Software"}]}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="locationType"
                        as={AppSelect}
                        label="Location Type"
                        size="small"
                        options={[{value: "Cloud", label: "Cloud"}, {value: "On-premise", label: "On-premise"}]}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="manufacturer"
                        as={AppTextField}
                        label="Manufacturer/Brand"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="manufacturerSerialNumber"
                        as={AppTextField}
                        label="Product serial number"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="co2Emissions"
                        as={AppTextField}
                        type={`number`}
                        label="CO2 emissions / hour (kgCO2e)"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="safetyKit"
                        as={AppTextField}
                        label="Safety kit"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}

