import { Button, Grid } from "@mui/material";
import { AppForm, AppSelect, AppTextField } from "../../../common";
import { FTE, Material } from "../../../types";
import { FTECreateSchema, infraAssetCreateSchema, materialCreateSchema } from "../../../validationSchema";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchFTEs } from "../../../../store/slices/ftesSlice";

interface IProcessFTEFormProps {
    onSubmit: (values: FTE) => void;
    onCancel: () => void;
}

export const ProcessFTEForm: React.FC<IProcessFTEFormProps> = ({ onSubmit, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();
    const { list: fteList, loading: infraLoading, error: fteError } = useSelector((state: RootState) => state.fte);

    useEffect(() => {
        if (!fteList?.length && !infraLoading) {
            const params = new URLSearchParams();
            params.append('templates', 'true');
            dispatch(fetchFTEs(params));
        }
    }, [dispatch, fteList]);

    useEffect(() => {
        if (fteError) {
            toast.error(`Failed to load infrastructure assets: ${fteError}`);
        }
    }, [fteError]);

    const initialValues: {fteID: string, duration: string} = { fteID: '', duration: '' };

    const fteOptions = fteList?.map((ic: FTE) => ({
        value: ic.id,
        label: `\u200B${ic.name}`,
    }));

    return (
        <AppForm 
            initialValues={initialValues} 
            validationSchema={FTECreateSchema}
            onSubmit={(values: {fteID: string, duration: string}) => {
                const fte: any = {...fteList?.find((ic: FTE) => ic.id === values.fteID)};

                if(!fte) return;

                fte.duration = values.duration;
                onSubmit(fte);
            }}
        >
            <Grid container columnSpacing={4}>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="fteID"
                        as={AppSelect}
                        label="Humar resource"
                        size="small"
                        options={fteOptions}
                    />
                </Grid>
                <Grid item xs={6} sx={{mb:2}}>
                    <Field
                        name="duration"
                        as={AppTextField}
                        label="Duration"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Add Human Resources</Button>
                </Grid>
            </Grid>
        </AppForm>
    )
}