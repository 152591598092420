import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Process, Location } from '../../../app/types';
import { AppConfirmDialog, AppDraggableModal } from '../../../app/common';
import { ProcessEditForm } from '../../../app/forms';
import { useDispatch } from 'react-redux';
import { deleteProcess, updateProcess } from '../../../store/slices/processesSlice';
import { AppDispatch, RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { fetchLocations } from '../../../store/slices/locationsSlice';

interface IProcessListTableProps {
    data: Process[];
}

type ModalKey = 'editTemplate' | 'deleteTemplate';

export const ProcessListTable: React.FC<IProcessListTableProps> = ({ data }) => {
    const dispatch: AppDispatch = useDispatch();
    const locations: Location[] = useSelector((state: RootState) => state.locations?.data?.locations as any);
    const [modalStates, setModalStates] = useState<{[key in ModalKey]: { isOpen: boolean; data: Process | null }}>({
        editTemplate: { isOpen: false, data: null },
        deleteTemplate: { isOpen: false, data: null },
    });

    useEffect(() => {
        const params = new URLSearchParams();
        dispatch(fetchLocations({ params }));
    }, [])

    const toggleModal = (modalKey: ModalKey, data: Process | null = null) => {
        setModalStates(prev => {
            const isOpen = prev[modalKey].isOpen;
            return {
                ...prev,
                [modalKey]: { isOpen: !isOpen, data },
            };
        });
    };

    const updateTemplate = (values: Process) => {
        dispatch(updateProcess(values));
        toggleModal('editTemplate');
    }

    const deleteTemplate = () => {
        const templateId: string = modalStates?.deleteTemplate?.data?.id || '';
        dispatch(deleteProcess(templateId));
        toggleModal('deleteTemplate');
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 720 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight:"bold"}}>Name</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Location</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>GHG</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Cost</TableCell>
                            <TableCell sx={{fontWeight:"bold"}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((process: Process) => (
                                <TableRow 
                                    hover 
                                    key={process.id}
                                    sx={{ 
                                        '& > *': { borderBottom: 'unset' },
                                        '&:last-child td, &:last-child th': { border: 0},
                                    }}
                                >
                                    <TableCell align="left">
                                        {process.name}
                                    </TableCell>
                                    <TableCell align="left">{(locations?.find((location: Location) => location.id === process.locationID) as any)?.name || ''}</TableCell>
                                    <TableCell align="left">{process.emissions} kgCO2e</TableCell>
                                    <TableCell align="left">{process.cost || 0} RON</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Edit template" onClick={() => toggleModal('editTemplate', process)}>
                                            <IconButton color="primary" size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete template"  onClick={() => toggleModal('deleteTemplate', process)}>
                                            <IconButton color="error" size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppDraggableModal
                    title={`Modify template`}
                    open={modalStates.editTemplate.isOpen}
                    onClose={() => toggleModal('editTemplate')}
                >
                    <ProcessEditForm
                        onSubmit={(data) => updateTemplate(data)}  
                        onCancel={() => toggleModal('editTemplate')}
                        processID={modalStates?.editTemplate?.data?.id || ''}
                    />
            </AppDraggableModal>
            <AppDraggableModal
                    title={`Delete template`}
                    open={modalStates.deleteTemplate.isOpen}
                    onClose={() => toggleModal('deleteTemplate')}
                >
                    <AppConfirmDialog
                        onSubmit={() => deleteTemplate()}  
                        onCancel={() => toggleModal('deleteTemplate')}
                        label={`Are you sure you want to delete the template?`}
                    />
            </AppDraggableModal>
        </>
    );
};
