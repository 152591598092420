import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Operation, Product } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';
import { toast } from 'react-toastify';

// Define the initial state
interface ProductsState {
    list: Product[];
    selectedProduct: Product | null;
    loading: boolean;
    error: string | null;
}

const initialState: ProductsState = {
    selectedProduct: null,
    list: [],
    loading: false,
    error: null,
};

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async (_, { rejectWithValue }) => {

        try {
            const response = await axiosClient.Products.getProducts();
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching product list, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProductById = createAsyncThunk(
    'products/fetchProductById',
    async (_productID: string, { rejectWithValue }) => {

        try {
            const params = new URLSearchParams();
            params.append('eager', 'true');
            const response = await axiosClient.Products.getProductById(_productID, params);
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching your product, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (_product: Partial<Product>, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Products.createProduct(_product);
            dispatch(fetchProducts());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem creating your product, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (_productID: string, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Products.deleteProduct(_productID);
            dispatch(fetchProducts());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem deleting your product, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const createOperation = createAsyncThunk(
    'products/createOperation',
    async (operation: Operation, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Operations.createOperation(operation);
            dispatch(fetchProducts());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem creating your operation, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const deleteOperation = createAsyncThunk(
    'products/deleteOperation',
    async (operationID: string, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Operations.deleteOperation(operationID);
            dispatch(fetchProducts());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem creating your operation, please try again');
            return rejectWithValue(error.message);
        }
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        // Add reducers here if you need synchronous actions
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProducts.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
            state.list = action.payload;
            state.loading = false;
        })
        .addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        })
        .addCase(fetchProductById.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchProductById.fulfilled, (state, action: PayloadAction<Product>) => {
            state.selectedProduct = action.payload;
            state.loading = false;
        })
        .addCase(fetchProductById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default productsSlice.reducer;