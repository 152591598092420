import React from 'react';
import { Button, Box, Typography, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppForm, AppTextField } from '../../app/common';
import { Field } from 'formik';
import { loginValidationSchema } from './loginValidationSchema';
import { toast, ToastContainer } from 'react-toastify';
import { Authentication, TenantInfo } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';
import { AppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import { fetchTenantInfo } from '../../store/slices/tenantInfoSlice';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();


  const handleSignUp = (event: React.FormEvent) => {
    event.preventDefault();
    navigate('/signup');
  };

  const handleForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();
    navigate('/passwordreset');
  };

  const checkAllFormsCompleted = (tenantData: TenantInfo) => {
    return (
      tenantData.name &&
      tenantData.name !== '' &&
      tenantData.legalEntities.length > 0 &&
      tenantData.locations.length > 0 &&
      tenantData.facilities.length > 0
    );
  };

  const login = async (values: Authentication) => {
    try {
        const data = await axiosClient.User.login(values);
        localStorage.setItem('user', JSON.stringify(data));
        
        // Dispatch the fetchTenantInfo action and wait for it to complete
        const resultAction = await dispatch(fetchTenantInfo());
        
        if (fetchTenantInfo.fulfilled.match(resultAction)) {
            const tenantData = resultAction.payload as TenantInfo; // Get the updated tenant data
            const shouldNavigateToDashboard = checkAllFormsCompleted(tenantData);
            
            if (shouldNavigateToDashboard) {
                navigate('/dashboard');
            } else {
                navigate('/onboarding');
            }
        } else {
            toast.error('Failed to load tenant information.');
            navigate('/onboarding');
        }
    } catch (error: any) {
        toast.error(error.message);
    }
  };

  const initialValues: Authentication = { email: '', password: ''}

  return (
      <>
        <ToastContainer />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Paper elevation={10} sx={{ padding: '40px', display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: 'fit-content', borderRadius: '15px' }}>
          <Typography variant="h4" sx={{ mb: 2 }}>Login</Typography>
          <AppForm initialValues={initialValues}  onSubmit={(values) => login(values)} validationSchema={loginValidationSchema}>
            <div>
              <Field
                name="email"
                as={AppTextField}
                label="Username"
                size="small"
              />
            </div>
            <div style={{marginTop: 15}}>
              <Field
                name="password"
                as={AppTextField}
                label="Password"
                size="small"
                type="password"
              />
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt:3 }}>
              <Button variant="contained" color="primary" type="submit" sx={{ mt: 1 }}>
                Login
              </Button>
            </Box>
          </AppForm>
          <Link href="#" onClick={handleSignUp} underline="hover" sx={{ mt: 1 }}>
            Sign Up
          </Link>
          <Link href="#" onClick={handleForgotPassword} underline="hover">
            Forgot Password?
          </Link>
        </Paper>
      </Box>
      </>
  );
};