import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Paper, PaperProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';

interface IAppDraggableModalProps {
    title: string;
    children: ReactNode;
    open: boolean;
    onClose: () => void;
}

const DraggablePaperComponent = (props: PaperProps) => {
    return (
        <Draggable handle=".draggable-handle" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const AppDraggableModal: React.FC<IAppDraggableModalProps> = ({ title, children, open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={DraggablePaperComponent}
            aria-labelledby="draggable-dialog-title"
            sx={{ '& .MuiDialog-paper': { minWidth: '360px', maxWidth: 'none' } }}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="draggable-handle">
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
};  