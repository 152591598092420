import React, { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { AppConfirmDialog, AppDraggableModal } from '../../../common';
import { DeleteForever } from '@mui/icons-material';

interface IProcessAssetsTableProps<T> {
    data: Array<T & { id?: string }>;
    tableMapping: any;
    onDelete: (data: any) => void;
}

type ModalKey = 'deleteTemplate';

export const ProcessAssetsTable = <T,>({ data, tableMapping, onDelete }: IProcessAssetsTableProps<T>): React.ReactElement => {
    const [modalStates, setModalStates] = useState<{[key in ModalKey]: { isOpen: boolean; data: T | null }}>({
        deleteTemplate: { isOpen: false, data: null },
    });

    const toggleModal = (modalKey: ModalKey, data: T | null = null) => {
        setModalStates(prev => {
            const isOpen = prev[modalKey].isOpen;
            return {
                ...prev,
                [modalKey]: { isOpen: !isOpen, data },
            };
        });
    };

    const deleteTemplate = () => {
        onDelete(modalStates?.deleteTemplate?.data);
        toggleModal('deleteTemplate');
    }

    return (
        <>
            <TableContainer sx={{ minWidth: '100%', my: '1rem' }} component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {tableMapping?.map((mapping: {label: string; key: string}) => <TableCell key={`tablehead${mapping.label}`} sx={{fontWeight:"bold", fontSize: '0.8rem'}}>{mapping.label}</TableCell>)}
                            <TableCell sx={{fontWeight:"bold", fontSize: '0.8rem'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item: any, key) => <TableRow key={`tablerow${item.id}`}>
                            {tableMapping?.map((mapping: {label: string; key: string}) => (<TableCell key={`tablecell${key}${item.id}`} sx={{fontSize: '0.8rem'}}>{item[mapping.key]}</TableCell>))}
                            <TableCell align="center">
                                <Tooltip title="Delete template"  onClick={() => toggleModal('deleteTemplate', item.id)}>
                                    <IconButton color="error" size="small">
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppDraggableModal
                    title={`Delete template`}
                    open={modalStates.deleteTemplate.isOpen}
                    onClose={() => toggleModal('deleteTemplate')}
                >
                    <AppConfirmDialog
                        onSubmit={() => deleteTemplate()}  
                        onCancel={() => toggleModal('deleteTemplate')}
                        label={`Are you sure you want to delete the template?`}
                    />
            </AppDraggableModal>
        </>
    );
};
