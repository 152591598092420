import { Button, CircularProgress, Grid } from "@mui/material";
import { AppForm } from "../../../common";
import { Process } from "../../../types";
import { processCreate } from "../../../validationSchema";
import { ProcessDetailsForm } from ".";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchProcessByID } from "../../../../store/slices/processesSlice";
import { useSelector } from "react-redux";
import { ProcessDynamicFields } from "./ProcessDynamicFields";

interface IProcessSummary {
    processID: string,
    onCancel: () => void
    onSubmit: (values: Process) => void
    showSubmit?: string
}

const getModifiedValuesFromProcess = (process: any) => {
    const modifyData = ['id', 'name', 'locationID', 'currencyID' ] //ownerID to be added
    let valuesToModify: any = {};
    modifyData.forEach((key: any) => {
        valuesToModify[key] = process[key];
    });
    return valuesToModify;
}

export const ProcessEditForm: React.FC<IProcessSummary> = ({ processID, onCancel, onSubmit, showSubmit = false }) => {
    const dispatch: AppDispatch = useDispatch();
    const { selectedProcess, loading, error } = useSelector((state: RootState) => state.processes);

    useEffect(() => {
        dispatch(fetchProcessByID(processID));
    }, []);

    return (
        selectedProcess ? 
            (
            <>
                <AppForm 
                    initialValues={getModifiedValuesFromProcess(selectedProcess)}
                    validationSchema={processCreate}
                    onSubmit={(values: any) => {
                        onSubmit(values);
                    }}
                >
                    <ProcessDetailsForm />
                </AppForm>
                <ProcessDynamicFields process={selectedProcess} />
               
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    {showSubmit &&
                        <Button type="submit" variant="outlined"  onClick={() => onSubmit(selectedProcess)}>{showSubmit}</Button>
                    }
                    <Button variant="outlined" onClick={onCancel}>Close</Button>
                </Grid>
            </>
            ) :
            (
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '300px', width: '500px' }}>
                    <CircularProgress />
                </Grid>
            )
    )
}