import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { DetailedAsset, Operation, Product } from '../../../app/types';
import { ContactPageSharp } from '@mui/icons-material';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { fetchProductById, fetchProducts } from '../../../store/slices/productsSlice';
import { useSelector } from 'react-redux';
import { AppForm } from '../../../app/common';
import { AppSelect } from '../../../app/common';
import { OperationSPS } from '../../../app/layout';
import { fetchEnergySources } from '../../../store/slices/energySourcesSlice';
import { fetchIscoCodes } from '../../../store/slices/iscoCodesSlice';


export const ProductSPS: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { list: productList, selectedProduct, loading, error } = useSelector((state: RootState) => state.products);
    const [productID, setProductID] = useState<string | null>('');

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchEnergySources());
        dispatch(fetchIscoCodes());
    }, []);

    useEffect(() => {
        if(!productID) return;

        dispatch(fetchProductById(productID))
    }, [productID]);

    const productOptions = productList?.map((ic: Product) => ({
        value: ic.id,
        label: `\u200B${ic.name}`,
    }));

    const transformPayload = (payload: Product): DetailedAsset[] => {
        const detailedAssets: DetailedAsset[] = [];
        payload.operations?.forEach(operation => {
            operation.processes?.forEach(process => {
                process.materials.forEach(material => {
                    detailedAssets.push({
                        id: material.id!,
                        name: material.name,
                        type: 'material',
                        unitQuantity: material.unitQuantity,
                        unitType: material.unitType,
                        unitPrice: material.unitPrice,
                        ghgPerUnit: material.ghgPerUnit,
                        supplierID: material.supplierID,
                        processID: material.processID,
                        currencyID: material.currencyID,
                        operationName: operation.name,
                        operationCost: operation.cost,
                        operationEmissions: operation.emissions,
                        processName: process.name,
                    });
                });
                process.ftes.forEach(fte => {
                    detailedAssets.push({
                        id: fte.id!,
                        name: fte.name,
                        type: 'fte',
                        duration: fte.duration,
                        gross_salary: fte.gross_salary,
                        month_percentage: fte.month_percentage,
                        iscoCodeID: fte.iscoCodeID,
                        currencyID: fte.currencyID,
                        cost: fte.cost,
                        operationName: operation.name,
                        operationCost: operation.cost,
                        operationEmissions: operation.emissions,
                        processName: process.name,
                    });
                });
                process.infrastructureAssets.forEach(infra => {
                    detailedAssets.push({
                        id: infra.id!,
                        name: infra.name,
                        type: 'infrastructureAsset',
                        duration: infra.duration,
                        energySourceID: infra.energySourceID,
                        cost: infra.cost,
                        ghg_per_duration: infra.ghg_per_duration,
                        operationName: operation.name,
                        operationCost: operation.cost,
                        operationEmissions: operation.emissions,
                        processName: process.name,
                        consumption: infra.consumption,
                    });
                });
            });
        });
        return detailedAssets;
    };
    const transformedData = selectedProduct ? transformPayload(selectedProduct) : [];

    return (
        <>
            <Grid container columnSpacing={4} sx={{ width:'500px', p: 2 }}>
                <Grid item xs={12} sx={{mb:2}}>
                    <AppForm 
                        initialValues={{product: ''}} 
                        validationSchema={null}
                        onSubmit={(values: string) => {
                            // console.log(values);
                        }}
                    >
                        <AppSelect
                            variant="outlined"
                            name="product"
                            label="Product"
                            size="small"
                            value={`test`}
                            options={productOptions}
                            onChange={(event: any) => setProductID(event.target.value)}
                        />
                    </AppForm>   
                </Grid> 
            </Grid>
            {loading ? 
                <Grid container columnSpacing={4} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', p: 2 }}>
                    <CircularProgress />    
                </Grid> :
                selectedProduct && (
                    <>
                        <Button startIcon={<ContactPageSharp />} variant="outlined" color="success" sx={{ my: 2, width: 'auto' }}>
                            Generate SPS
                        </Button>
                        <Box sx={{ marginBottom: 2, p: 2, ml: 0 }} component={Paper}>
                            <Grid container columnSpacing={2} sx={{ py: 1, px: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 720 }} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight:"bold"}}>Operation</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Process Name</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Process Requirements</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Fuel</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Fuel Cost</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Energy #KW</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Energy Cost</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>HR FTE</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>HR Cost</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Other #</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Other Cost</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>GHG</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <OperationSPS data={transformedData}></OperationSPS>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}></TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>Product total:</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>{selectedProduct.emissions} kgCO2e</TableCell>
                                                <TableCell sx={{fontWeight:"bold"}}>{selectedProduct.cost} RON</TableCell>
                                            </TableRow>
                                        </TableFooter>
                                        
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    </>
                )
            }
        </>
    );
};
