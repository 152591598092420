import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
export const useTokenValidation = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null); // Initialize as null to indicate loading state
  const navigate = useNavigate();

  const hasAccessToken = () => {
    const storedUserData = localStorage.getItem('user');

    if (storedUserData) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const valid = hasAccessToken();
    setIsAuthorized(valid);
    if (!valid) {
      setIsAuthorized(false);
      navigate('/login');
    }
  }, [navigate]);

  return isAuthorized;
};
