import { Grid, Paper } from "@mui/material"
import { AppForm, AppSelect } from "../../../common"
import { Process } from "../../../types"
import { ProcessEditForm } from "./ProcessEditForm"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../store/store"
import { fetchProcesses } from "../../../../store/slices/processesSlice"
import { useDispatch } from "react-redux"

interface IProcessAddFormProps {
    onSubmit: (values: Process) => void;
    onCancel: () => void;
    productAndProcess: any;
}

export const ProcessAddForm: React.FC<IProcessAddFormProps> = ({ onSubmit, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();
    const [process, setProcess] = useState<string | any>(null);
    const { list, selectedProcess, loading, error } = useSelector((state: RootState) => state.processes);

    useEffect(() => {
        dispatch(fetchProcesses());
    }, []);

    const processOptions = list?.map((ic: Process) => ({
        value: ic.id || '',
        label: `\u200B${ic.name}`,
    }));

    return (
        <>
            <Grid container columnSpacing={4} sx={{ width:'500px', p: 2 }}>
                <Grid item xs={12} sx={{mb:2}}>
                    <AppForm 
                        initialValues={{subProcesses: null}} 
                        validationSchema={null}
                        onSubmit={(values) => {
                            // console.log(values);
                        }}
                    >
                        <AppSelect
                            variant="outlined"
                            name="process"
                            label="Process"
                            size="small"
                            options={processOptions}
                            onChange={e => setProcess(e.target.value)}
                        />
                    </AppForm>   
                </Grid> 
            </Grid>
            {process && (
                <Grid container sx={{p: 2}} component={Paper}>
                    <Grid item >
                        <ProcessEditForm processID={process} onCancel={onCancel} onSubmit={onSubmit} showSubmit={`Add process to operation`} />
                    </Grid>
                </Grid>
            )}
        </>
    )
}