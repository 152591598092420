import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { InfastructureAsset } from "../../app/types";

interface IAssetListTableProps {
    data: InfastructureAsset[] | null;
}

export const AssetListTable: React.FC<IAssetListTableProps> = ({data}) => { 
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 720 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight:"bold"}}>Name</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Consumption</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Duration</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Emissions</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Cost</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Manufacturer</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Serial Number</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Location Type</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>GHG Per Duration</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Cost Per Consumption</TableCell>
                            <TableCell sx={{fontWeight:"bold"}}>Type</TableCell>
                            <TableCell sx={{fontWeight:"bold"}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((asset) => (
                            <>
                                <TableRow 
                                    hover 
                                    key={asset.id}
                                    sx={{ 
                                        '& > *': { borderBottom: 'unset' },
                                        '&:last-child td, &:last-child th': { border: 0}
                                    }}
                                >
                                    <TableCell align="left">{asset.name}</TableCell>
                                    <TableCell align="left">{asset.consumption}</TableCell>
                                    <TableCell align="left">{asset.duration}</TableCell>
                                    <TableCell align="left">{asset.co2Emissions}</TableCell>
                                    <TableCell align="left">{asset.cost}</TableCell>
                                    <TableCell align="left">{asset.manufacturer}</TableCell>
                                    <TableCell align="left">{asset.manufacturerSerialNumber}</TableCell>
                                    <TableCell align="left">{asset.locationType}</TableCell>
                                    <TableCell align="left">{asset.ghg_per_duration}</TableCell>
                                    <TableCell align="left">{asset.cost}</TableCell>
                                    <TableCell align="left">{asset.cost_per_consumption}</TableCell>
                                    <TableCell align="left">{asset.type}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Edit material">
                                            <IconButton color="primary" size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete material">
                                            <IconButton color="error" size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    </>)
}