import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { AppNavBar, AppSideBar } from '../../app/layout';
import { ValueChainProducts, ValueChainProcessTemplates, ProductSPS } from './products';
import { Protected } from '../../app/security';

export const ValueChain: React.FC = () => {
  return (
    <>
      <AppNavBar />
      <ToastContainer />
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', pt: 8 }}>
        <AppSideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <ToastContainer />
          <Routes>
            <Route element={<Protected />}>
              <Route path="/" element={<Navigate to="/valuechain/products" replace />} />
              <Route path="/products" element={<ValueChainProducts />} />
              <Route path="/process-templates" element={<ValueChainProcessTemplates />} />
              <Route path="/generatesps" element={<ProductSPS />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </>
  );
};