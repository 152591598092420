import React, { forwardRef } from 'react';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';

interface IFormProps {
    initialValues: any;
    validationSchema: any;
    onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
    children: React.ReactNode;
}

export const AppForm = forwardRef<FormikProps<any>, IFormProps>(
  ({ initialValues, validationSchema, onSubmit, children }, ref) => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={ref}
      enableReinitialize
      validateOnMount={true}
    >
      {() => (
        <Form>
          {children}
        </Form>
      )}
    </Formik>
  ),
);
