import { LegalName } from "../../app/types";

export const tableMapping: Record<string, any> = {
    infrastructureTable: {
        fieldMapping: [{label: 'Name', key: 'name'}, {label: 'Type', key: 'type'}, {label: 'Duration', key: 'duration'}],
    },
    materialsTable: {
        fieldMapping: [{label: 'Name', key: 'name'}, {label: 'Type', key: 'type'}, {label: 'Quantity', key: 'unitQuantity'}],
    },
    ftesTable: {
        fieldMapping: [{label: 'Name', key: 'name'}, {label: 'Cost', key: 'cost'}, {label: 'Duration', key: 'duration'}],
    },
};
