import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EnergySource } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';

interface EnergySourceState {
    list: EnergySource[];
    loading: boolean;
    error: string | null;
    lastFetched: number | null;
}


const initialState: EnergySourceState = {
    list: [],
    loading: false,
    error: null,
    lastFetched: null,
};

export const fetchEnergySources = createAsyncThunk(
    'energySources/fetchEnergySources',
    async (_, { getState, rejectWithValue }) => {
        const state = getState() as { energySources: EnergySourceState };
        if (state.energySources.list.length > 0) {
            // If cities are already fetched, return early
            return state.energySources.list;
        }

        try {
            const response = await axiosClient.General.getEnergySources();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const energySlice = createSlice({
    name: 'energySources',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEnergySources.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEnergySources.fulfilled, (state, action: PayloadAction<EnergySource[]>) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(fetchEnergySources.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default energySlice.reducer;