import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { Location, Locations } from '../../app/types';

interface FacilitiesState {
    data: Locations | null;
    loading: boolean;
    error: string | null;
}

interface FetchFacilitiesParams {
    params: URLSearchParams;
}

const initialState: FacilitiesState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchFacilities = createAsyncThunk(
    'facilities/fetchFacilities',
    async ({ params }: FetchFacilitiesParams, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Onboarding.getLocations(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const reconcileFacilities = createAsyncThunk(
    'facilities/reconcileFacilities',
    async (data: Locations, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Onboarding.reconcileFacilities(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const facilitiesSlice = createSlice({
    name: 'facilities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacilities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFacilities.fulfilled, (state, action: PayloadAction<Location[]>) => {
                state.data = { locations: action.payload.length > 0 ? action.payload : [{ name: "", cityID: "", ownership: "" } as Location] };
                state.loading = false;
            })
            .addCase(fetchFacilities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(reconcileFacilities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reconcileFacilities.fulfilled, (state, action: PayloadAction<Locations>) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(reconcileFacilities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default facilitiesSlice.reducer;
