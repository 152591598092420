import React, { useRef } from 'react';
import { Button, Grid, Typography } from '@mui/material';

type UploadProps = {
  label: string;
  fileType: string;
  fileName: string;
  handleFileUpload: (file: File, type: string) => void;
};

export const Upload: React.FC<UploadProps> = ({ label, fileType, fileName, handleFileUpload }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
      <Grid item xs={3}>
        <Button
          variant="contained"
          component="label"
          sx={{ width: "300px" }}
        >
          {label}
          <input
            type="file"
            ref={fileInputRef}
            hidden
            onChange={(e) => {
              if (e.target.files) {
                handleFileUpload(e.target.files[0], fileType);
              }
            }}
          />
        </Button>
      </Grid>
      <Grid item xs={4}>
        {fileName && (
          <Typography>Uploaded: {fileName}</Typography>
        )}
      </Grid>
    </Grid>
  );
};