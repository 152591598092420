import * as Yup from "yup";

export const legalName = Yup.object({
    name: Yup.string().required("Legal Name is required"),
});

// Schema for a single legal entity
const legalEntitySchema = Yup.object({
    name: Yup.string().required("Entity name is required"),
    type: Yup.string().required("Type is required"),
    economicActivityCodeIDs: Yup.array().of(Yup.string()).min(1, "At least one CAEN/NACE code is required").required("CAEN/NACE codes required"),
});

export const legalEntities = Yup.object({
    legalEntities: Yup.array().of(legalEntitySchema).required("At least one legal entity is required"),
});

export const affiliates = Yup.object({
    affiliates: Yup.string().required("Affiliates is required"),
});

const locationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    cityID: Yup.string().required("City is required"),
    ownership: Yup.string().required("Ownership is required"),
});

export const locations = Yup.object({
   locations: Yup.array().of(locationSchema).required("At least one location is required"),
});

const facilitySchema = Yup.object({
    name: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    facility: Yup.string().required("Facility is required"),
});

export const facilities = Yup.object({
    facilities: Yup.array().of(facilitySchema).required("At least one facility is required"),
});

const datacenterSchema = Yup.object({
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
});

export const datacenters = Yup.object({
    datacenters: Yup.array().of(datacenterSchema).required("At least one datacenter is required"),
});

export const officer = Yup.object({
    officer: Yup.string().required("Officer is required"),
});

export const rep = Yup.object({
    officer: Yup.string().required("Officer is required"),
});