import { useState, useEffect } from "react";
import axiosClient from "../../app/api/axiosClient";
import { toast } from "react-toastify";
import { organizationalDetailsFormConfigs } from "../constants";

interface UseFetchInitialValuesResult<T> {
    data: T | null;
    loading: boolean;
    error: string | null;
}

export const useFetchInitialValues = (formId: string | null): UseFetchInitialValuesResult<any> => {
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!formId) return;

        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                let data: any;
                switch (formId) {
                    case "legalName":
                        const tenantNameResponse = await axiosClient.Tenant.getName();
                        data = {name: tenantNameResponse}
                        break;
                    case "legalEntities":
                        const legalEntitiesResponse = await axiosClient.Onboarding.getLegalEntities();
                        data = legalEntitiesResponse.length > 0 ? { legalEntities: legalEntitiesResponse } : organizationalDetailsFormConfigs[1].initialValues;
                        break;
                    case "locations":
                        const locationsParams = new URLSearchParams({ type: 'Location'});
                        const locationsResponse = await axiosClient.Onboarding.getLocations(locationsParams);
                        data = locationsResponse.length > 0 ? { locations: locationsResponse } : organizationalDetailsFormConfigs[2].initialValues;
                        break;

                    case "facilities":
                        const facilitiesParams = new URLSearchParams({ type: 'Facility'});
                        const facilitiesResponse = await axiosClient.Onboarding.getLocations(facilitiesParams);
                        data = facilitiesResponse.length > 0 ? { locations: facilitiesResponse } : organizationalDetailsFormConfigs[3].initialValues;
                        break;
                    default:
                        data = null;
                }
                setData(data);
            } catch (err: any) {
                console.error(`Error fetching data for ${formId}:`, err);
                setError(err.message || 'An error occurred while fetching data');
                toast.error(err.message || 'Failed to load data');
            }
            setLoading(false);
        };

        fetchData();
    }, [formId]);

    return { data, loading, error };
};
