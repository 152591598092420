import { useField } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

type AppTextFieldProps = TextFieldProps & {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    validate?: (value: string) => string | undefined;
    name: string;
    maxLength?: number;
};

export const AppTextField: React.FC<AppTextFieldProps> = ({ onChange, validate, name, maxLength, ...props }) => {
    
    const [field, meta] = useField<string>({ name, validate });
    const hasError = (meta.touched && meta.error) ? true : false;
    
    return (
        <TextField
            {...field}
            {...props}
            variant="outlined"
            fullWidth
            onChange={onChange}
            inputProps={{ maxLength: maxLength }}
            error={hasError}
            helperText={hasError ? meta.error : ''}
            InputLabelProps={{
                shrink: Boolean(field.value) || undefined,  // Shrink the label if there's a value
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& input:-webkit-autofill:focus': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                    '& input:-webkit-autofill': {
                        WebkitTextFillColor: 'black',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    }
                },
                bgcolor: '#fff',
                flexGrow: '0'
            }}
        />
    )
}
