import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../app/api/axiosClient';
import { LegalEntity, LegalEntities } from '../../app/types';

interface LegalEntitiesState {
    data: LegalEntities | null;
    loading: boolean;
    error: string | null;
}

const initialState: LegalEntitiesState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchLegalEntities = createAsyncThunk(
    'legalEntities/fetchLegalEntities',
    async (_, { rejectWithValue }) => {
        try {
           const response = await axiosClient.Onboarding.getLegalEntities();
           return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const reconcileLegalEntities = createAsyncThunk(
    'legalEntities/reconcileLegalEntities',
    async (data: LegalEntities, { rejectWithValue }) => {
        try {
            const response = await axiosClient.Onboarding.reconcileLegalEntities(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const legalEntitiesSlice = createSlice({
    name: 'legalEntities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLegalEntities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLegalEntities.fulfilled, (state, action: PayloadAction<LegalEntity[]>) => {
                state.data = { legalEntities: action.payload.length > 0 ? action.payload : [{  id: "", name: "", type: '', economicActivityCodeIDs: [] } as LegalEntity] };
                state.loading = false;
            })
            .addCase(fetchLegalEntities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(reconcileLegalEntities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reconcileLegalEntities.fulfilled, (state, action: PayloadAction<LegalEntities>) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(reconcileLegalEntities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default legalEntitiesSlice.reducer;