import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Process } from '../../app/types';
import axiosClient from '../../app/api/axiosClient';
import { toast } from 'react-toastify';


interface ProductsState {
    list: Process[];
    selectedProcess: Process | null;
    loading: boolean;
    error: string | null;
}

const initialState: ProductsState = {
    selectedProcess: null,
    list: [],
    loading: false,
    error: null,
};

export const fetchProcesses = createAsyncThunk(
    'processes/fetchProcesses',
    async (_, { rejectWithValue }) => {

        try {
            const response = await axiosClient.Processes.getProcesses();
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching processes list, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProcessByID = createAsyncThunk(
    'processes/fetchProcessByID',
    async (processID: string, { rejectWithValue }) => {

        try {
            const params = new URLSearchParams();
            params.append('eager', 'true');
            const response = await axiosClient.Processes.getProcessesByID(processID, params);
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching processes list, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const createProcess = createAsyncThunk(
    'processes/createProcess',
    async (process: Partial<Process>, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Processes.createProcess(process);
            dispatch(fetchProcesses());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem creating the process, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const deleteProcess = createAsyncThunk(
    'processes/deleteProcess',
    async (processID: string, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Processes.deleteProcess(processID);
            dispatch(fetchProcesses());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching product list, please try again');
            return rejectWithValue(error.message);
        }
    }
);

export const updateProcess = createAsyncThunk(
    'processes/updateProcess',
    async (process: Partial<Process>, { rejectWithValue, dispatch }) => {

        try {
            const response = await axiosClient.Processes.updateProcess(process);
            dispatch(fetchProcesses());
            return response;
        } catch (error: any) {
            toast.warning('There was a problem fetching product list, please try again');
            return rejectWithValue(error.message);
        }
    }
);

const processesSlice = createSlice({
    name: 'processes',
    initialState,
    reducers: {
        // Add reducers here if you need synchronous actions
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProcesses.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchProcesses.fulfilled, (state, action: PayloadAction<Process[]>) => {
            state.list = action.payload;
            state.loading = false;
        })
        .addCase(fetchProcesses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        })
        .addCase(fetchProcessByID.pending, (state) => {
            state.loading = true;
            state.selectedProcess = null;
            state.error = null;
        })
        .addCase(fetchProcessByID.fulfilled, (state, action: PayloadAction<Process>) => {
            state.selectedProcess = action.payload;
            state.loading = false;
        })
        .addCase(fetchProcessByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        })
    },
});

export default processesSlice.reducer;