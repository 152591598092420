import { memo, useCallback } from 'react';
import { DetailedAsset } from '../../types';
import { TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

type OperationSPSProps = {
    data: DetailedAsset[];
};

const calculateRowSpanForOperation = (operationName: string, data: DetailedAsset[]): number => {
    return data.filter(item => item.operationName === operationName).length;
};
const calculateRowSpanForProcess = (processName: string, operationName: string, data: DetailedAsset[]): number => {
    return data.filter(item => item.operationName === operationName && item.processName === processName).length;
};

let previousOperationName = '';
let previousProcessName = '';

export const OperationSPS: React.FC<OperationSPSProps> = ({ data }) => {
    return (
        <>
             {data.map((asset, index) => {
                const showOperationName = asset.operationName !== previousOperationName;
                if (showOperationName) {
                    previousOperationName = asset.operationName;
                }

                const showProcessName = asset.processName !== previousProcessName || showOperationName;
                if (showProcessName) {
                    previousProcessName = asset.processName;
                }
                return (
                    <TableRow key={asset.id}>
                        {showOperationName && (
                            <TableCell rowSpan={calculateRowSpanForOperation(asset.operationName, data)}>
                                {asset.operationName}
                            </TableCell>
                        )}
                        {showProcessName && (
                            <TableCell rowSpan={calculateRowSpanForProcess(asset.processName, asset.operationName, data)}>
                                {asset.processName}
                            </TableCell>
                        )}
                        
                        <AssetSPS asset={asset}/>

                        {showOperationName && (
                            <TableCell rowSpan={calculateRowSpanForOperation(asset.operationName, data)}>
                                {asset.operationEmissions} kgCO2e
                            </TableCell>
                        )}
                        {showOperationName && (
                            <TableCell rowSpan={calculateRowSpanForOperation(asset.operationName, data)}>
                                {asset.operationCost} RON
                            </TableCell>
                        )}
                    </TableRow>
                );
            })}
        </>
    );
}

type AssetSPSProps = {
    asset: DetailedAsset;
};

export const AssetSPS: React.FC<AssetSPSProps> = memo(({ asset }) => {

    const { list: energySourceList, loading: loadingEnergySources, error: errorEnergySources } = useSelector((state: RootState) => state.energySources);
    const { codes: iscoCodesList, loading: loadingIscoCodes, error: errorIscoCodes } = useSelector((state: RootState) => state.iscoCodes);

    const assetTransformData = () => {
        switch(asset.type) {
            case 'fte':
                return {
                    prerequisite: iscoCodesList.find(el => el.id === asset.iscoCodeID)?.description,
                    fuel: '',
                    fuelCost: '',
                    energy: '',
                    energyCost: '',
                    fte: asset.month_percentage,
                    fteCost: `${asset.cost} RON`,
                    other: '',
                    otherCost: '',
                }
                break;
            case 'infrastructureAsset':
                const energySource = energySourceList.find(el => el.id === asset.energySourceID)?.name;

                return {
                    prerequisite: energySourceList.find(el => el.id === asset.energySourceID)?.name,
                    fuel: energySource !== 'Electricity' ? `${asset.consumption} ${energySourceList.find(el => el.id === asset.energySourceID)?.unit}` : '',
                    fuelCost: energySource !== 'Electricity' ? `${asset.cost} RON` : '',
                    energy: energySource === 'Electricity' ? `${asset.consumption} ${energySourceList.find(el => el.id === asset.energySourceID)?.unit}` : '',
                    energyCost:  energySource === 'Electricity' ? `${asset.cost} RON` : '',
                    fte: '',
                    fteCost: '',
                    other: '',
                    otherCost: '',
                }
                break;
            case 'material':

                return {
                    prerequisite: asset.name,
                    fuel: '',
                    fuelCost: '',
                    energy: '',
                    energyCost: '',
                    fte: '',
                    fteCost: '',
                    other: `${asset.unitQuantity} ${asset.unitType}`,
                    otherCost: `${Number(asset.unitQuantity) * Number(asset.unitPrice)} RON` ,
                }
                break;
            default:
                return {
                    prerequisite: ''
                }
          }   
    };

    const mappedData = assetTransformData();

    return (
        <>
            <TableCell variant="body">{mappedData.prerequisite}</TableCell>
            <TableCell>{mappedData.fuel}</TableCell>
            <TableCell>{mappedData.fuelCost}</TableCell>
            <TableCell>{mappedData.energy}</TableCell>
            <TableCell>{mappedData.energyCost}</TableCell>
            <TableCell>{mappedData.fte}</TableCell>
            <TableCell>{mappedData.fteCost}</TableCell>
            <TableCell>{mappedData.other}</TableCell>
            <TableCell>{mappedData.otherCost}</TableCell>
        </>
    );
})
