import React, { useState, useRef, useCallback, useEffect } from "react";
import { AppForm, AppTabNavigationButtons } from "../../app/common";
import { organizationalDetailsFormConfigs } from "../../app/constants/formConfigs";
import { Tabs, Tab, Box } from "@mui/material";
import { FormikProps } from "formik";
import { toast } from 'react-toastify';
import { useFetchInitialValues } from "../../app/hooks/useFetchInitialValues";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { formActionsMapping } from "../../app/utils";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../app/api/axiosClient";
import { fetchTenantInfo } from "../../store/slices/tenantInfoSlice";

export const OrganizationalDetails: React.FC = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [formVersion, setFormVersion] = useState<Record<number, number>>({});
    const formikRefs = useRef<(FormikProps<any> | null)[]>(organizationalDetailsFormConfigs.map(() => null));
    const dispatch = useDispatch<AppDispatch>();
    const activeFormId = organizationalDetailsFormConfigs[activeTab]?.id || null;
    const navigate = useNavigate();
    const { data: formData, loading: formLoading, error: formError } = useFetchInitialValues(activeFormId);

    useEffect(() => {
        if (formError) {
            toast.error(`Failed to load initial values: ${formError}`);
        }
    }, [formError]);

    useEffect(() => {
        setFormVersion(prev => ({
            ...prev,
            [activeTab]: new Date().getTime()
        }));
    }, [activeTab]);

    const navigateTab = useCallback((targetTab: number) => {
        const currentFormRef = formikRefs.current[activeTab];
        if (!currentFormRef) {
            setActiveTab(targetTab);
            return;
        }

        currentFormRef.submitForm().then(() => {
            const isFormValid = currentFormRef.isValid;
            const isFormDirty = currentFormRef.dirty;

            if (isFormValid || !isFormDirty) {
                if (targetTab > activeTab && activeTab === organizationalDetailsFormConfigs.length - 1) {
                    setActiveTab(0);
                } else {
                    setActiveTab(targetTab);
                }
            } else {
                toast.warning("Please complete the current form or correct the errors before moving to another tab.");
            }
        });
    }, [activeTab, navigate]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        navigateTab(newValue);
    };

    const handleNext = () => {
        navigateTab(activeTab + 1);
    };

    const handleBack = () => {
        navigateTab(activeTab - 1);
    };

    const handleSubmit = (values: any, id: string) => {
        const formConfig = formActionsMapping[id];

        if (formConfig) {
            dispatch(formConfig.action(formConfig.transformer(values)));

            // Fetch the updated tenant data after the form is successfully submitted
            axiosClient.Tenant.getTenant().then((updatedTenantData) => {
                dispatch(fetchTenantInfo()); // Update the tenant info in the Redux store
            }).catch((error) => {
                toast.error('Failed to update tenant data after submission.');
            });
        }
    };

    const getInitialValues = (config: any) => {
        if (config.id === activeFormId && formData) {
            return formData;
        }
       
        return config.initialValues;
    };

    return (
        <Box sx={{ marginX: 'auto', maxWidth: 'lg', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Tabs value={activeTab} onChange={handleTabChange} sx={{ flexGrow: 1 }}>
                    {organizationalDetailsFormConfigs.map((config, index) => (
                        <Tab label={config.title} key={index} />
                    ))}
                </Tabs>
                <AppTabNavigationButtons 
                    onNext={handleNext} 
                    onBack={handleBack} 
                    isNextDisabled={false} 
                    isBackDisabled={activeTab === 0} 
                />
            </Box>
            {organizationalDetailsFormConfigs.map((config, index) => (
                <div role="tabpanel" hidden={activeTab !== index} key={index}>
                    {activeTab === index && (
                        <AppForm
                            key={`${config.id}-${formVersion[activeTab] || 'initial'}`}
                            ref={(el) => (formikRefs.current[index] = el)}
                            initialValues={getInitialValues(config)}
                            validationSchema={config.validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                if (formikRefs.current[index]?.dirty) {
                                    handleSubmit(values, config.id);
                                }
                                setSubmitting(false);
                            }}
                        >
                            <config.content />
                        </AppForm>
                    )}
                </div>
            ))}
        </Box>
    );
};