import * as Yup from "yup";

export const signupValidationSchema = Yup.object({
    firstName: Yup.string().required("Username is required"),
    lastName: Yup.string().required("Lastname is required"),
    password: Yup.string().required("Password is required"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password')], "Passwords must match")
        .min(6, "Password must be at least 6 characters long")
        .required("Password confirmation is required"),
    email: Yup.string()
        .required("Email is required")
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Email is invalid"
        ),
});
