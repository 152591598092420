import { Box, Typography } from "@mui/material"
import { MaterialListTable } from "./MaterialListTable"
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchMaterials } from "../../store/slices/materialsSlice";

export const Materials: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { list: materialsList, loading: infraLoading, error: materialError } = useSelector((state: RootState) => state.materials);

    useEffect(() => {
        if (!materialsList?.length && !infraLoading) {
            const params = new URLSearchParams();
            dispatch(fetchMaterials(params));
        }
    }, [dispatch, materialsList, infraLoading]);

    
    return <>
         <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#757575' }}>
                Materials & Suppliers
            </Typography>
        </Box>
        <MaterialListTable data={materialsList} />
    </>
}