import * as Yup from "yup";

const rawMaterialsSchema = Yup.object({
    name: Yup.string().required("Material name is required"),
    unitQuantity: Yup.string().required("Unit Quantity is required"),
    unitType: Yup.string().required("Unit Type is required"),
    unitPrice:  Yup.string().required("Unit Type is required"),
    co2Unit:  Yup.string().required("CO2/Unit Type is required"),
});

const consumableSchema = Yup.object({
    name: Yup.string().required("Consumable name is required"),
    unitQuantity: Yup.string().required("Unit Quantity is required"),
    unitType: Yup.string().required("Unit Type is required"),
    unitPrice:  Yup.string().required("Unit Type is required"),
    co2Unit:  Yup.string().required("CO2/Unit Type is required"),
});

export const processDetails = Yup.object({
    processName: Yup.string().required("Process Name is required"),
    time: Yup.string().required("Process Name is required"),
    timeUnit: Yup.string().required("Process Name is required"),
    processOwner: Yup.string().required("Process Name is required")
});

export const processTaxonomy = Yup.object({ 
    location:  Yup.string().required("Location is required"),
    naceCodes: Yup.array().of(Yup.string()).min(1, "At least one NACE code is required").required("NACE codes required"),
});

export const processMaterials = Yup.object({ 
    supplier:  Yup.string().notRequired(),
    rawMaterialsData: Yup.array().when('supplier', (supplier, schema) => {
        return !!supplier.toString() ? schema.of(rawMaterialsSchema).min(1).required() : schema.of(rawMaterialsSchema).min(0);
     })
});

export const processInfrastructureSchema = Yup.object({ 
    infraAssets:  Yup.array().of(Yup.string()).min(0),
    consumables: Yup.array().of(consumableSchema).min(0)
});

export const subProcessEditSchema = Yup.object({
    processName: Yup.string().required("Process Name is required"),
    location:  Yup.string().required("Location is required"),
    naceCodes: Yup.array().of(Yup.string()).min(1, "At least one NACE code is required").required("NACE codes required"),
});

export const processCreate = Yup.object({
    name: Yup.string().required("Process Name is required"),
    currencyID: Yup.string().required("Currency is required"),
});